import { Alert, AppBar, Button, CircularProgress, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import { get, patchJson } from "./http";
import LoginScreen from "./LoginScreen";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

import ExportLink from "./ExportLink";
import TruncatedJson from "./TruncatedJson";
import ExternalInteractionsForTraceButton from "./ExternalInteractionsForTraceButton";

interface EventsQuery {
    skip: number
    from: Dayjs
    to: Dayjs
    memberId:string|undefined
}
interface TimestampedEvent {
    timestamp: number
    event: any
}
const eventsSearchUrl = (query: EventsQuery): string => `/api/events?from=${query.from.unix() * 1000}&to=${query.to.unix() * 1000}&skip=${query.skip}${query.memberId ? `&member-id=${query.memberId}` : ""}`
const getEvents = (query: EventsQuery): Promise<TimestampedEvent[]> => {
    return new Promise((resolveSuccess, resolveFailed) => {
        const { from, to } = query
        get(eventsSearchUrl(query), (status, text) => {
            if (status === 200) {
                resolveSuccess(JSON.parse(text))
            } else {
                resolveFailed(`Server said ${status}: ${text}`)
            }
        })
    })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { style?: CSSProperties }) => {
    const { style } = props

    return <Box style={style}>
        <LoginScreen
            message="Sign-in using your email address"
            loggedInContent={(user) => {
                if (!user.isInsider) {
                    return <Stack spacing={5}>
                        <h1>Restricted</h1>
                        <Box style={{ textAlign: "center" }}>
                            <Alert color="warning" style={{ display: "inline-block", minWidth: "300px" }}>
                                This is just for caruso employees & such.
                            </Alert>
                        </Box>
                    </Stack>
                } else {
                    return (<>
                        <Stack spacing={4}>
                            <Typography variant="h2">External API Events</Typography>
                            <TheEventsViewer />
                        </Stack>
                    </>)
                }
            }} />
    </Box>
}

export const TheEventsViewer = (props: { style?: CSSProperties, defaultLookbackMinutes?:number, memberIdFilter?:string, memberLinkMaker?: (memberId: string) => ReactNode }) => {
    const { style, memberIdFilter, defaultLookbackMinutes, memberLinkMaker } = props
    const [eventsPages, setEventsPages] = useState<TimestampedEvent[][]>()
    const [skip, setSkip] = useState(0)

    const [isBusy, setBusy] = useState<boolean>()
    const [rangeStart, setRangeStart] = useState<Dayjs>(dayjs().subtract(defaultLookbackMinutes ?? 5, "minute"))
    const [rangeEnd, setRangeEnd] = useState<Dayjs>(dayjs())

    const query: EventsQuery = {
        skip: skip,
        from: rangeStart,
        to: rangeEnd,
        memberId: memberIdFilter
    }

    useEffect(() => {
        setBusy(true)
        setEventsPages(undefined)
        getEvents(query)
            .then(page => {
                setSkip(0)
                setEventsPages([page])
            })
            .finally(() => setBusy(false))
    }, [rangeStart, rangeEnd])


    useEffect(() => {
        if (skip > 0) {
            setBusy(true)
            getEvents(query)
                .then(page => {
                    setEventsPages((eventsPages ?? []).concat([page]))
                })
                .finally(() => setBusy(false))
        }
    }, [skip])


    const cellStyle:CSSProperties = {
        verticalAlign:"top",
        minWidth:"200px",
    }
    console.log("page", eventsPages)
    return (<Stack spacing={2} style={style}>
        <Stack direction="row" spacing={2} justifyContent={"end"}><ExportLink name="Export CSV" url={eventsSearchUrl(query) + "&format=csv"} /> <ExportLink name="Export JSON" url={eventsSearchUrl(query) + "&format=json"} /></Stack>

        <Stack direction="row" spacing={3} style={{ marginTop: "20px" }}>
            <DateTimePicker label="Earliest" value={rangeStart} onChange={v => setRangeStart(dayjs(v))} />
            <DateTimePicker label="Latest" value={rangeEnd} onChange={v => setRangeEnd(dayjs(v))} />
        </Stack>

        <TableContainer component={Paper}>
            <Table stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Event ID</TableCell>
                        <TableCell align="left">Timestamp</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="left">MemberId</TableCell>
                        <TableCell align="left">Data</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        eventsPages?.flatMap(page => page.map(te => {
                            const memberId = te.event.member.member_id
                            const eventId = te.event.eventId
                            const ts = dayjs(te.timestamp)
                            return (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={cellStyle} align="left">{eventId}</TableCell>
                                    <TableCell style={cellStyle} align="left">
                                        <Stack spacing={1}>
                                            <Box>{ts.format("YYYY-MM-DD h:mm a Z")} </Box>
                                            <Box><a href={`https://www.epochconverter.com/?q=${te.timestamp}`} target="_blank" rel="noreferrer">{te.timestamp}</a></Box>
                                        </Stack>
                                    </TableCell>
                                    <TableCell style={cellStyle} align="left">{te.event.type}</TableCell>
                                    <TableCell style={cellStyle} align="left">{memberLinkMaker ? memberLinkMaker(memberId) : memberId}</TableCell>
                                    <TableCell style={cellStyle} align="left"><TruncatedJson value={te.event} /><ExternalInteractionsForTraceButton traceId={eventId} /></TableCell>
                                </TableRow>
                            )
                        }))
                    }

                </TableBody>
            </Table>
        </TableContainer>
        {isBusy && <CircularProgress />}
        {(eventsPages && eventsPages.length > 0 && lastItem(eventsPages)?.length !== 0) && <Button onClick={() => setSkip(eventsPages?.flatMap(page => page).length ?? 0)}>Load More</Button>}
        {(eventsPages?.length === 0) && <Typography style={{ textAlign: "center" }}>No results</Typography>}
    </Stack>)
}

const lastItem = <T,>(array: T[]): T | undefined => {
    return array[array.length - 1]
}
import { useState } from "react"
import { ActivityListItem } from "./api"
import { get } from "./http"
import { Alert, Box, Card, CardContent, Chip, CircularProgress, Stack, } from "@mui/material"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import dayjs from "dayjs"
import { formattedColorAmount } from "./Util"
import ActivityLink from "./ActivityLink"
import { detailFn } from "./ActivityList"

const COINS = "coins"

export interface CoinTrancheDto {
    id: string,
    amount: number,
    whenAdded: number,
    whenExpires: number,
    uses: CoinUse[],
    isExpired: boolean,
    amountUsed: number,
    amountUnused: number,
    ref:ActivityListItem,
}
export interface CoinUse {
    amount: number,
    whenUsed: number,
    ref: ActivityListItem,
}

export interface CoinHistoryDto {
    memberId: string,
    totalEarned:number,
    totalSpent:number,
    totalExpired:number,
    tranches: CoinTrancheDto[],
}


const getCoinTranchesForMember = (memberId: string, onResult: (results: CoinHistoryDto | undefined, error: string | undefined) => void) => {
    get(`/api/users/${memberId}/coin-tranches`, (status, body) => {
        if (status === 200) {
            onResult(JSON.parse(body), undefined)
        } else {
            onResult(undefined, `Status ${status}: ${body}`)
        }
    })
}


// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { memberId: string, setFocus: (a: ActivityListItem) => void }) => {
    const { memberId, setFocus } = props
    const [error, setError] = useState<string>()
    const [coinHistory, setCoinHistory] = useState<CoinHistoryDto>()
    getCoinTranchesForMember(memberId, (h, error) => {
        setCoinHistory(h)
        setError(error)
    })

    return (<>
        <Stack>
            <Box>Total Earned: {coinHistory && formattedColorAmount(coinHistory.totalEarned, COINS)}</Box>
            <Box>Total Spent: {coinHistory && formattedColorAmount(coinHistory.totalSpent, COINS)}</Box>
            <Box>Total Expired: {coinHistory && formattedColorAmount(coinHistory.totalExpired, COINS)}</Box>
        </Stack>

        <Paper>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead >
                    <TableRow >
                        <TableCell>ID</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Trigger</TableCell>
                        <TableCell>When</TableCell>
                        <TableCell>Expiration Date</TableCell>
                        <TableCell align="left">Amount</TableCell>
                        <TableCell align="left">Used</TableCell>
                        <TableCell align="left">Unused</TableCell>
                        <TableCell align="left">Coin Uses</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {error && <Alert color="error">{error}</Alert>}
                    {coinHistory?.tranches.map((t, idx) => {
                        const when = dayjs(t.whenAdded)
                        const whenExpires = dayjs(t.whenExpires)
                        return (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Box># {t.id}</Box>
                                </TableCell>

                                <TableCell component="th" scope="row">
                                    <Stack spacing={2}>
                                        <Box>{t.isExpired && <Chip color="error" label="Expired"/>}</Box>
                                        <Box>{t.amountUnused === 0 && <Chip color="error" label="Fully Used"/>}</Box>
                                        <Box>{((t.amountUsed !== 0 ) && (t.amountUsed < t.amount)) && <Chip color="warning" label="Partially Used"/>}</Box>
                                        <Box>{(!t.isExpired && t.amountUsed === 0) && <Chip color="success" label="Unused"/>}</Box>
                                    </Stack>
                                </TableCell>

                                <TableCell>
                                <Stack spacing={2}>
                                    <Box>{t.ref.details.sl_id}</Box>
                                    <Box>
                                        <ActivityLink
                                            activity={t.ref}
                                            onClick={() => setFocus(t.ref)} /></Box>
                                    <Box>{detailFn(t.ref)}</Box>
                                    <Box>Applied: {formattedColorAmount(t.amount ?? 0, COINS)}</Box>
                                    {/* <Box>Total: {formattedColorAmount(u.ref.details.sl_total ?? 0, COINS)}</Box> */}
                                    {/* <Box>{when.from(dayjs())}</Box>
                                    <Box>{when.format("YYYY-MM-DD")}</Box>
                                    <Box>{when.format("h:mm a")}</Box> */}
                                </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Stack spacing={2}>
                                        <Box>{when.from(dayjs())}</Box>
                                        <Box>{when.format("YYYY-MM-DD")}</Box>
                                        <Box>{when.format("h:mm a")}</Box>
                                    </Stack>
                                </TableCell>

                                <TableCell component="th" scope="row">
                                    <Stack spacing={2}>
                                        <Box>{whenExpires.from(dayjs())}</Box>
                                        <Box>{whenExpires.format("YYYY-MM-DD")}</Box>
                                        <Box>{whenExpires.format("h:mm a")}</Box>
                                    </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {formattedColorAmount(t.amount ?? 0, COINS)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {formattedColorAmount(t.amountUsed ?? 0, COINS)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {formattedColorAmount(t.amountUnused ?? 0, COINS)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Stack spacing={2}>
                                        {t.uses.map(u => {
                                            const when = dayjs(u.whenUsed)
                                            return <Card>
                                                <CardContent>
                                                    <Stack spacing={2}>
                                                        <Box>
                                                            <ActivityLink
                                                                activity={u.ref}
                                                                onClick={() => setFocus(u.ref)} />#{u.ref.details.sl_id}
                                                        </Box>
                                                        <Box>{detailFn(u.ref)}</Box>
                                                        <Box>Applied: {formattedColorAmount(u.amount ?? 0, COINS)}</Box>
                                                        <Box>Total: {formattedColorAmount(u.ref.details.sl_value ?? 0, COINS)}</Box>
                                                        <Box>{when.from(dayjs())}</Box>
                                                        <Box>{when.format("YYYY-MM-DD")}</Box>
                                                        <Box>{when.format("h:mm a")}</Box>
                                                    </Stack>
                                                </CardContent>
                                            </Card>
                                        })}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </Paper>

        {!coinHistory && <CircularProgress />}
    </>
    )

}
import { Button, CircularProgress } from "@mui/material"
import { useEffect, useRef, useState } from "react"

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { children: string, onSuccess: (assetUrl: string) => void }) => {
    const { children, onSuccess } = props
    const uploadRef = useRef<HTMLInputElement>(null)
    const [file, setFile] = useState<File>()
    
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setFile((event.target.files && event.target.files[0]) || undefined)
    }
    const handleSubmit = ()=>{
        if(file){
            // fetch("/api/assets", {
            //     method: "POST",
            //     body: file.stream(),
            //     duplex: 'half',
            //     headers: {
            //         "Content-type": file.type
            //     }
            // } as any);
            file.arrayBuffer().then(bytes => {
                fetch("/api/assets", {
                    method: "POST",
                    body:bytes ,
                    headers: {
                        "Content-type": file.type
                    }
                } as any).then(r => {
                    if(r.status === 200){
                        return r.text()
                    }else{
                        throw Error(`server said ${r.status}`)
                    }
                }).then(text => {
                    onSuccess(`https://extras.carusosignature.com/api/assets/${JSON.parse(text).id}`)
                    setFile(undefined)
                })
            })
        }
    
      }
    useEffect(handleSubmit, [file])
    
    return (<>
        {file && <CircularProgress/>}
        <input style={{display:"none"}} ref={uploadRef} type="file" onChange={handleChange} />
        {!file && <Button onClick={()=>uploadRef.current?.click()}>{children}</Button>}
    </>)
}

import { Card, CardContent, Divider, Link, MenuItem, Stack, Typography } from "@mui/material"
import { CSSProperties, useState } from "react";
import LabeledSelect from "./LabeledSelect";

const makeDownloadHandler = (name: string, url: string): React.MouseEventHandler<HTMLAnchorElement> => {
  return (e) => {
    e.preventDefault()
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.target = "_blank"
    link.click();
  }
};

export const QRCode = (props: { content: string, style?: CSSProperties }) => {
  const { content, style } = props
  return (<img style={style} src={`/api/qr-code-generator?style=rounded&content=${encodeURIComponent(content)}`} alt="QC Code" />)
}

export const QRDownloads = (props: { name: string, content: string }) => {
  const { name, content } = props

  return (<>
    <Link style={{ cursor: "pointer" }} onClick={makeDownloadHandler(`${name}-qr-code-standard.svg`, `/api/qr-code-generator?style=standard&content=${encodeURIComponent(content)}`)}>Standard QR</Link>
    <Link style={{ cursor: "pointer" }} onClick={makeDownloadHandler(`${name}-qr-code-rounded.svg`, `/api/qr-code-generator?style=rounded&content=${encodeURIComponent(content)}`)}>Rounded QR</Link>
  </>)
}


export const DeepLinksListing = (props:{baseUrl:string}) => {
  const {baseUrl} = props
  const prefix = `${baseUrl}/#`

  const paths: Record<string, string> = {

    "Home": "/",
    "Member Home": "/dashboard",
    "Ways to Earn": "/dashboard/earn-coin-view",
    "Activity History": "/dashboard/recent-transactions",
    "Available Rewards": "/dashboard/rewards",
    "Caruso Portfolio": "/dashboard/view-all-portfolio",
    "Explore Exclusive": "/dashboard/exclusives",
    "Member Rewards": "/dashboard/manage-wallet",
    "Member Cards": "/user/manage-my-cards",
    "Member Link Card Screen": "/user/link-card-alert",
    "Member Profile": "/user/my-profile",
    "Terms of Service": "/terms",
    "Privacy Policy": "/privacy",
    "Help": "/user/help",
    "Demo Tag 1": "/tags/f6dc7858-524b-4d98-960b-75397282818f",
    "Demo Tag 2": "/tags/76a0f841-149d-4ac6-8b6a-d35f87eb7087",
  }

  const referrers = ["stu"]

  return (<>
    {Object.keys(paths).map(name => {
      const link = paths[name]
      const url = prefix + link
      return <Card>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="h5">{name}</Typography>
            <Link href={url}>{link}</Link>
            <QRCode style={{ height: "90px", width: "90px" }} content={url} />
            <QRDownloads name={name} content={url} />
          </Stack>
        </CardContent>
      </Card>
    })}
  </>
  )
}

const hostOptions = ["https://carusosignature.com", "https://app.carusosignature.com", "http://localhost:8081"]
  
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const prefix = "https://carusosignature.com/#"
  const [host, setHost] = useState(hostOptions[0])

  const externalLinks:Record<string, string> = {
    "testing-apple.com":"https://www.apple.com",
    "testing-apple.com-deep":"https://www.apple.com/ipad/",
    "testing-caruso.com":"https://www.caruso.com",
    "testing-caruso.com-deep":"https://caruso.com/newsroom/",
  }
  const referrers = ["stu"]
  return (
    <Stack>
      <Typography variant="h3">Links For Testing Deep Linking</Typography>
      <Stack spacing={2} style={{ margin: "20px" }}>
        <LabeledSelect
          label="Host" 
          labelId="host-field" 
          value={host}
          onChange={e=>setHost(e.target.value)}
          >
          {hostOptions.map(hostname=>{
            return <MenuItem value={hostname}>{hostname}</MenuItem>
          })}

        </LabeledSelect>
        <Divider>Paths</Divider>
        <DeepLinksListing baseUrl={host}/>
        <Divider>External Link Tracking</Divider>
        {Object.keys(externalLinks).map(key => {
          const url = externalLinks[key]
          return <><Link href={`${host}/links/${key}/${encodeURIComponent(url)}`}>{key} - {url}</Link></>
        })}
        <Divider>Signup Referrers</Divider>
        {referrers.map(referrerCode => {
          const url = `https://crso.io/j/${referrerCode}`
          return <>
            <Link href={url}>{url}</Link>
          </>
        })}
      </Stack>
    </Stack>
  )
}
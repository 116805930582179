/* eslint-disable jsx-a11y/anchor-is-valid */
import { Alert, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, ImageList, ImageListItem, ImageListItemBar, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { ReactNode, } from "react";
import { get, patchJson } from "./http";
import LoginScreen, { UserInfoDto } from "./LoginScreen";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Unarchivecon from '@mui/icons-material/Unarchive';
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { MerchantCategory, FidelStuff, CarusoMap, MapWithContext, CarusoMapProperty, CarusoTenantStatus, CarusoTenant, TenantEarnRulesHistoryEntry, TenantEarnRules, PropertyMapChange, TenantUpdate, TenantDeletion, TenantAddition } from "./api";

import Grid from '@mui/material/Grid';
import SimpleMarkdown from "./SimpleMarkdown";
import AccountingReport from "./AccountingReport";
import TestAccountsPage from "./TestAccountsPage";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TabbedPanels from "./TabbedPanels";
import { flatten } from "./Util";
import SlimHeader from "./SlimHeader";
import DataApp, { DataSetListing } from "./DataApp";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import TagPickupsAdminPage, { SingleTagAdminPage } from "./TagPickupsAdminPage";
import ActivityPage from "./ActivityPage";
import EditPropertyDialog from "./EditPropertyDialog";
import AddPropertyDialog from "./AddPropertyDialog";
import RoutedTabs from "./RoutedTabs";
import MarkdownPage from "./MarkdownPage";
import DomoDashboardsPage from "./DomoDashboardsPage";
import ExportLink from "./ExportLink";
import { TheEventsViewer } from "./EventsViewer";
import ReferralTagsPage from "./ReferralTagsPage";
import { DeepLinksListing } from "./DeepLinks";
import { getContextualizedMap } from "./api-actions";
import TestSMSPage from "./TestSMSPage";
import MemberAccountPage from "./MemberAccountPage";


const sendChange = (change: PropertyMapChange, onComplete: (status: number, body: string) => void) => {
    patchJson(
        '/api/map',
        JSON.stringify(change),
        (status, body) => {
            onComplete(status, body)
        })
}

const EditMerchantDialog = (props: { property: CarusoMapProperty, existing?: CarusoTenant | undefined, onChange: (update: TenantUpdate | TenantAddition) => void, onClose: () => void }) => {
    const { property, existing, onChange, onClose } = props

    const [isWorking, setWorking] = React.useState(false)
    const [name, setName] = React.useState<string>(existing?.name ?? "")
    const [address, setAddress] = React.useState<string>(existing?.address ?? "")
    const [categories, setCategories] = React.useState<MerchantCategory[]>(existing?.categories ?? [])
    const [error, setError] = React.useState<string | undefined>(undefined)

    console.log("Categories are ", categories)

    const addCategory = (c: MerchantCategory) => {
        setCategories([c].concat(categories))
    }
    const removeCategory = (c: MerchantCategory) => {
        setCategories(categories.filter(n => n !== c))
    }

    const doSave = () => {
        setWorking(true)
        setError(undefined)
        if (name && address && categories) {
            if (existing) {
                const update: TenantUpdate = {
                    type: "tenant-update",
                    propertyId: property.id,
                    tenant: {
                        ...existing,
                        name: name,
                        address: address,
                        categories: categories
                    }
                }

                onChange(update)
            } else {
                const update: TenantAddition = {
                    type: "tenant-addition",
                    propertyId: property.id,
                    name: name,
                    address: address,
                    categories: categories
                }

                onChange(update)
            }
        }
    }

    return <Dialog open={true} onClose={onClose}>
        <DialogTitle>{existing ? "Edit" : "Add Pending"} Tenant @ {property.name}</DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
            <Stack spacing={2}>
                {error ? <Alert color="warning">{error}</Alert> : null}
                <TextField
                    required
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label="Tenant Name" />

                <TextField
                    required
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    label="Address" />

                <FormLabel component="legend">Categories</FormLabel>
                <FormGroup>
                    {[MerchantCategory.Dining, MerchantCategory.Shopping].map(category => {
                        return (<FormControlLabel control={<Checkbox
                            checked={categories.indexOf(category) > -1}
                            onChange={e => e.target.checked ? addCategory(category) : removeCategory(category)} />} label={category} />)
                    })}
                </FormGroup>
            </Stack>
        </DialogContent>
        <DialogActions>
            {isWorking ? <CircularProgress /> : <>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" disabled={!(name && address && categories)} onClick={doSave}>Save</Button>
            </>}
        </DialogActions>

    </Dialog>
}

type ButtonColor = "error" | "primary" | "secondary" | "info" | "success" | "warning"

const ConfirmDialog = (props: { title: string, body: string, actionName: string, actionColor: ButtonColor, onConfirm: () => void, onCancel: () => void }) => {
    const { title, body, actionName, actionColor, onConfirm, onCancel } = props

    return (<Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={true}
        onClose={onCancel}
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
            {body}
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button variant="contained" color={actionColor} onClick={onConfirm}>{actionName}</Button>
        </DialogActions>
    </Dialog>)
}

const ProgressDialog = (props: {}) => {
    return (<Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={true}
    >
        <DialogTitle>Applying Changes</DialogTitle>
        <DialogContent dividers>
            <CircularProgress />
        </DialogContent>
        <DialogActions>
        </DialogActions>
    </Dialog>)
}

const PropertiesPanel = (props: { subject: Object }) => {
    const { subject } = props
    const properties = flatten("", subject)
    return (<>
        <TableContainer component={Paper}>
            <Table >
                <TableBody>
                    {
                        Object.keys(properties).sort().map(key => (

                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {key}
                                </TableCell>
                                <TableCell>{(properties as any)[key]}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>)
}

const makeNewRuleEntry = (rules: TenantEarnRulesHistoryEntry[]): TenantEarnRulesHistoryEntry => {
    const now = new Date().getTime()
    const activeRule = rules.length > 0 ? rules[rules.length - 1] : undefined
    return {
        starting: now,
        rules: {
            coinsMultiple: activeRule?.rules.coinsMultiple ?? 1
        }
    }
}

const CoinEarnEntries = (props: { rules: TenantEarnRulesHistoryEntry[], onChange: (newRules: TenantEarnRulesHistoryEntry[]) => void }) => {
    const { rules, onChange } = props


    const addNew = () => {
        onChange(rules.concat([makeNewRuleEntry(rules)]))
    }
    return (<>
        {/* {rules.length === 0 && <Stack direction={"row"} spacing={2}><Typography>No multiples defined</Typography></Stack>} */}
        {rules.map((entry, idx) => {
            const fireEntryUpdate = (n: TenantEarnRulesHistoryEntry) => {
                const newRules = [...rules]
                newRules[idx] = n
                onChange(newRules)
            }
            const fireRulesUpdate = (n: TenantEarnRules) => {
                fireEntryUpdate({
                    ...entry,
                    rules: n
                })
            }
            const removeIt = () => {
                onChange(rules.filter((e, i) => i !== idx))
            }
            const setMultiple = (v: number) => {
                fireRulesUpdate({
                    ...entry.rules,
                    coinsMultiple: v
                })
            }
            const setStarting = (d: Dayjs) => {
                fireEntryUpdate({
                    ...entry,
                    starting: d.unix() * 1000
                })
            }
            console.log("starting is", entry.starting, dayjs(entry.starting))
            return <Stack direction={"row"} spacing={2}>
                <DateTimePicker label="Starting" value={dayjs(entry.starting)} onChange={v => v && setStarting(v)} />
                <TextField
                    label="Coin Earn Multiple"
                    type="number"
                    value={entry.rules.coinsMultiple}
                    onChange={e => { setMultiple(parseFloat(e.target.value)) }} />
                <IconButton onClick={removeIt}><DeleteIcon /></IconButton>
            </Stack>

        })}
        <Box><Button onClick={addNew}>Add Schedule Item</Button></Box>
    </>)

}

const LoyaltySettingsDialog = (props: { property: CarusoMapProperty, tenant: CarusoTenant, map: CarusoMap, doUpdate: (update: TenantUpdate) => void, onCancel: () => void }) => {
    const { tenant, property, map, doUpdate, onCancel } = props
    const membership = tenant.loyaltyProgramMembership
    const [rulesEntries, setRulesEntries] = React.useState(membership?.earnRulesHistory ?? [])
    const [active, setActive] = React.useState(membership?.active ?? false)
    const [displayName, setDisplayName] = React.useState(membership?.displayName ?? tenant.name)
    const existingCodeExchangeRedemptionCodes = membership?.codeExchangeRedemptionCodes ?? []
    const [newCodeExchangeRedemptionCodes, setNewCodeExchangeRedemptionCodes] = React.useState<string[]>([])


    const takenCodes = new Set(map.properties.flatMap(prop => prop.tenants).flatMap(t => t.loyaltyProgramMembership?.codeExchangeRedemptionCodes ?? []))

    const onConfirm = () => {

        doUpdate({
            type: "tenant-update",
            tenant: {
                ...tenant,
                loyaltyProgramMembership: {
                    active: active,
                    displayName: displayName,
                    earnRulesHistory: rulesEntries,
                    codeExchangeRedemptionCodes: existingCodeExchangeRedemptionCodes.concat(newCodeExchangeRedemptionCodes),
                }
            },
            propertyId: property.id
        })
    }

    const generateNewCode = () => {
        while (true) {
            const n = Math.round(randomBetween(1000, 9999)).toString()
            if (!takenCodes.has(n)) {
                return n
            }
        }
    }

    return (<Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '80%' } }}
        fullScreen
        open={true}
        onClose={onCancel}
    >
        <DialogTitle>Tenant Loyalty Program Configuration</DialogTitle>
        <DialogContent >
            <Stack spacing={2}>
                <FormControl fullWidth>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={active} onChange={e => setActive(e.target.checked)} />} label="Active" />
                    </FormGroup>
                </FormControl>

                <TextField id="standard-basic" label="Display Name" variant="outlined" value={displayName} onChange={e => setDisplayName(e.target.value)} />

                <FormLabel >Coin Earn Schedule</FormLabel>
                <CoinEarnEntries
                    rules={rulesEntries}
                    onChange={setRulesEntries} />

                <FormLabel>Code Exchange Redemption Codes</FormLabel>
                <Box><Button onClick={() => setNewCodeExchangeRedemptionCodes(newCodeExchangeRedemptionCodes.concat([generateNewCode().toString()]))}>Add Code</Button></Box>
                <Box>
                    {existingCodeExchangeRedemptionCodes.map(code => {
                        return <Chip label={code} />
                    })}

                    {newCodeExchangeRedemptionCodes.map((code, idx) => {
                        const deleteCode = () => {
                            const prefix = newCodeExchangeRedemptionCodes.slice(0, idx)
                            const suffix = newCodeExchangeRedemptionCodes.slice(idx + 1)
                            setNewCodeExchangeRedemptionCodes(prefix.concat(suffix))
                        }
                        return <Chip label={code} onDelete={deleteCode} />
                    })}
                </Box>
            </Stack>

        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button variant="contained" onClick={onConfirm}>Save</Button>
        </DialogActions>
    </Dialog>)
}

const randomBetween = (low: number, high: number): number => ((Math.random() * (high - low)) + low)

const NewCodeExchangeRedemptionCodeDialog = (props: { onConfirm: (code: string) => void, onCancel: () => void }) => {
    const { onConfirm, onCancel } = props
    const [redemptionCode, setRedemptionCode] = React.useState(randomBetween(1000, 9999).toString())

    return (<Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={true}
        onClose={onCancel}
    >
        <DialogTitle>Add Code Exchange Redemption Code</DialogTitle>
        <DialogContent dividers>
            <Typography>These are the codes that are used to track usage when items are consumed using the 'code exchange' method.  These codes are supplied to members at usage time by a CSR, and then entered into their device.</Typography>
            <TextField label="redemption code" value={redemptionCode} onChange={e => setRedemptionCode(e.target.value)}></TextField>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button variant="contained" color={"info"} onClick={() => onConfirm(redemptionCode)}>Add</Button>
        </DialogActions>
    </Dialog>)
}

const FidelEditsDialog = (props: { property: CarusoMapProperty, tenant: CarusoTenant, fidelStuff: FidelStuff, doUpdate: (update: TenantUpdate) => void, onCancel: () => void }) => {
    const { tenant, property, fidelStuff, doUpdate, onCancel } = props

    const [fidelLocationId, setFidelLocationId] = React.useState(tenant.fidelLocationId)

    const fidelLocation = (fidelLocationId && fidelStuff) ? fidelStuff.locations.find(l => l.id === fidelLocationId) : undefined

    const onConfirm = () => {
        doUpdate({
            type: "tenant-update",
            tenant: {
                ...tenant,
                fidelLocationId: fidelLocationId
            },
            propertyId: property.id
            // fidel
        })
    }


    return (<Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="md"

        open={true}
        onClose={onCancel}
    >
        <DialogTitle>Fidel -&gt; {tenant.name} @ {property.name}</DialogTitle>
        <DialogContent dividers>
            <Stack spacing={2}>
                <Typography variant="subtitle1">Mapping</Typography>
                <FormControl fullWidth>
                    {!fidelStuff && <CircularProgress />}
                    {fidelStuff &&
                        <>
                            <InputLabel id="demo-simple-select-label">Fidel Location</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fidelLocationId}
                                label="Fidel Location"
                                onChange={e => setFidelLocationId(e.target.value)}
                            >
                                {fidelStuff.locations.map(l => {
                                    return <MenuItem value={l.id}>{fidelStuff.brands.find(b => b.id === l.brandId)?.name} @ {l.address}</MenuItem>
                                })}
                            </Select>
                        </>
                    }
                </FormControl>
                {fidelLocation?.active}

                {fidelLocation && <>
                    <Typography variant="subtitle1">Details</Typography>
                    <PropertiesPanel subject={fidelLocation} />
                </>}
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button variant="contained" onClick={onConfirm}>Save</Button>
        </DialogActions>
    </Dialog>)
}


interface ChangePendingConfirmation {
    title: string
    body: string
    actionName: string
    actionColor: ButtonColor
    change: PropertyMapChange
}
const TenantsList = (props: {
    contextualizedMap: MapWithContext,
    composite: CarusoMapProperty,
    tenants: CarusoTenant[],
    fidelStuff: FidelStuff,
    onChange: (change: PropertyMapChange) => void
}) => {

    const { tenants, contextualizedMap, composite, fidelStuff, onChange } = props

    const [tenantBeingEdited, setTenantBeingEdited] = React.useState<CarusoTenant>()
    const [fidelBeingEdited, setFidelBeingEdited] = React.useState<CarusoTenant>()
    const [loyaltyBeingEdited, setLoyaltyBeingEdited] = React.useState<CarusoTenant>()
    const [changePendingConfirmation, setChangePendingConfirmation] = React.useState<ChangePendingConfirmation>()

    const doDelete = (tenant: CarusoTenant) => {
        const deletion: TenantDeletion = {
            type: "tenant-deletion",
            tenantId: tenant.id
        }
        setChangePendingConfirmation({
            title: "Confirm Delete",
            body: "Deleting this tenant cannot be undone.  This action will be logged.",
            actionName: "Delete",
            actionColor: "error",
            change: deletion
        })
    }

    const changeConfirmed = () => {
        if (changePendingConfirmation) {
            setChangePendingConfirmation(undefined)
            onChange(changePendingConfirmation.change)
        }
    }

    const doStatusChange = (tenant: CarusoTenant, status: CarusoTenantStatus) => {
        const update: TenantUpdate = {
            type: "tenant-update",
            tenant: {
                ...tenant,
                status: status
            },
            propertyId: composite.id
        }

        setChangePendingConfirmation({
            title: "Confirm Status Change",
            body: `Are you sure you want to set "${tenant.name}" to ${status}?`,
            actionName: "Update",
            actionColor: "info",
            change: update
        })
    }

    if (tenants.length === 0) {
        return <div>No tenants</div>
    } else {
        return (<>
            {changePendingConfirmation && <ConfirmDialog title={changePendingConfirmation.title} body={changePendingConfirmation.body} actionName={changePendingConfirmation.actionName} actionColor={changePendingConfirmation.actionColor} onConfirm={changeConfirmed} onCancel={() => setChangePendingConfirmation(undefined)} />}
            {loyaltyBeingEdited && <LoyaltySettingsDialog tenant={loyaltyBeingEdited} property={composite} map={contextualizedMap.map} doUpdate={update => { setLoyaltyBeingEdited(undefined); onChange(update) }} onCancel={() => setLoyaltyBeingEdited(undefined)} />}
            {fidelBeingEdited && <FidelEditsDialog tenant={fidelBeingEdited} property={composite} fidelStuff={fidelStuff} doUpdate={update => { setFidelBeingEdited(undefined); onChange(update) }} onCancel={() => setFidelBeingEdited(undefined)} />}
            {tenantBeingEdited && <EditMerchantDialog existing={tenantBeingEdited} property={composite} onChange={update => { setTenantBeingEdited(undefined); onChange(update) }} onClose={() => setTenantBeingEdited(undefined)} />}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Categories</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Fidel Location</TableCell>
                            <TableCell>Loyalty Membership</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tenants.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((tenant) => {

                            const loyaltyStatus = (tenant.loyaltyProgramMembership) ? (tenant.loyaltyProgramMembership.active ? "active" : "inactive") : "none"
                            const loyaltyName = tenant.loyaltyProgramMembership?.displayName
                            const loyaltyNameOverride = (loyaltyName && tenant.name != loyaltyName) ? ` (${loyaltyName})` : undefined

                            if (loyaltyNameOverride) {
                                console.log("WARNING: overrides ", { name: tenant.name, override: loyaltyName })
                            }

                            const selectedFidelLocation = fidelStuff.locations.find(l => l.id == tenant.fidelLocationId)
                            const selectedFidelBrand = fidelStuff.brands.find(b => b.id == selectedFidelLocation?.brandId)

                            const warnings = contextualizedMap.context.tenantWarnings.filter(w => w.tenantId === tenant.id)

                            return (
                                <TableRow
                                    key={tenant.name + tenant.fidelLocationId + tenant.address}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {tenant.name}
                                    </TableCell>
                                    <TableCell>{tenant.categories.join(", ")}</TableCell>
                                    <TableCell>{
                                        <a
                                            href={`https://www.google.com/maps/search/?api=1&query=${tenant.address}`}
                                            target="_blank" rel="noreferrer"
                                        >{tenant.address}</a>}</TableCell>
                                    <TableCell>
                                        <Stack spacing={3}>
                                            <a style={{ cursor: "pointer" }} onClick={() => setFidelBeingEdited(tenant)}>{selectedFidelBrand && selectedFidelLocation ? `${selectedFidelBrand.name} @ ${selectedFidelLocation.address}` : "--none--"}</a>
                                            {warnings.map(w => <Alert color="warning"><div style={{ whiteSpace: "pre-wrap" }}>{w.description}</div></Alert>)}
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <a style={{ cursor: "pointer" }} onClick={() => setLoyaltyBeingEdited(tenant)}>{loyaltyStatus} {loyaltyNameOverride} </a>
                                    </TableCell>

                                    <TableCell align="right">
                                        <Stack direction="row">
                                            {(tenant.status === CarusoTenantStatus.new) && <Tooltip title="Activate Tenant"><IconButton onClick={() => doStatusChange(tenant, CarusoTenantStatus.active)}><PlayCircleIcon /></IconButton></Tooltip>}
                                            {(!tenant.status || tenant.status === CarusoTenantStatus.active) && <Tooltip title="Archive Tenant"><IconButton onClick={() => doStatusChange(tenant, CarusoTenantStatus.inactive)}><ArchiveIcon /></IconButton></Tooltip>}
                                            {tenant.status === CarusoTenantStatus.inactive && <Tooltip title="Activate Tenant"><IconButton onClick={() => doStatusChange(tenant, CarusoTenantStatus.active)}><Unarchivecon /></IconButton></Tooltip>}
                                            <Tooltip title="Edit Tenant"><IconButton onClick={() => setTenantBeingEdited(tenant)}><EditIcon /></IconButton></Tooltip>
                                            <Tooltip title="Permanently Delete Tenant"><IconButton onClick={() => doDelete(tenant)}><DeleteIcon /></IconButton></Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>)
    }
}

const PropertyEntry = (props: { map: MapWithContext, composite: CarusoMapProperty, onChange: () => void, doChange: (change: PropertyMapChange) => void }) => {
    const { composite, map, onChange, doChange } = props
    const [showAddDialog, setShowAddDialog] = React.useState(false)
    const fidelStuff = map.context.fidelStuff
    const [showEditor, setShowEditor] = React.useState(false)


    const respondToAdd = () => {
        console.log("Done")
        setShowAddDialog(false)
        onChange()
    }

    const handleUpdate = (update: PropertyMapChange) => {
        console.log("Got change", update)
        doChange(update)
    }

    return <Stack spacing={2} style={{ textAlign: "left" }} >
        {showEditor && <EditPropertyDialog property={composite} onChange={change => { handleUpdate(change); setShowEditor(false) }} onClose={() => setShowEditor(false)} />}
        {showAddDialog ? <EditMerchantDialog property={composite} onChange={change => { handleUpdate(change); setShowAddDialog(false) }} onClose={respondToAdd} /> : null}
        <Box style={{ backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url(${composite?.imageUrl})` }}>
            <Typography variant="h2" style={{ textAlign: "center", padding: "20px", paddingTop: "50px", paddingBottom: "50px", background: "rgba(0, 0, 0, 0.5)", color: "white" }}>{composite.name}</Typography>
        </Box>
        <Stack spacing={4} style={{ margin: "15px" }}>

            <Typography variant="h4">Overview <Tooltip title="Edit Property"><IconButton onClick={() => setShowEditor(true)}><EditIcon /></IconButton></Tooltip></Typography>


            <Stack spacing={2} >
                <Typography>
                    Property ID #{composite.id}
                </Typography>
                <Typography>
                    {composite?.address}
                </Typography>
                <Typography>
                    <a target="_blank" href={composite?.url} rel="noreferrer">{composite?.url}</a>
                </Typography>
            </Stack>
            <Typography variant="h4">Tenants</Typography>

            <Box><Button onClick={() => setShowAddDialog(true)}>Add Tenant</Button></Box>
            <TabbedPanels tabs={{
                "Active": <TenantsList key="active" contextualizedMap={map} fidelStuff={fidelStuff} composite={composite} tenants={composite.tenants.filter(t => !t.status || t.status === CarusoTenantStatus.active)} onChange={handleUpdate} />,
                "Pending": <TenantsList key="pending" contextualizedMap={map} fidelStuff={fidelStuff} composite={composite} tenants={composite.tenants.filter(t => t.status === CarusoTenantStatus.new)} onChange={handleUpdate} />,
                "Archived": <TenantsList key="archived" contextualizedMap={map} fidelStuff={fidelStuff} composite={composite} tenants={composite.tenants.filter(t => t.status === CarusoTenantStatus.inactive)} onChange={handleUpdate} />
            }} />
        </Stack>
    </Stack>
}

const LinkBox = (props: { name: string, markdown?: string, children?: ReactNode }) => (
    <Box style={{ lineHeight: "1.5", display: "block", verticalAlign: "top" }}>
        <Typography variant="h5">{props.name}</Typography>

        {props.markdown && <SimpleMarkdown linkTarget={'_blank'}>{props.markdown}</SimpleMarkdown>}
        {props.children}
    </Box>
)


const NavigateLink = (props: { name: string, url: string }) => {
    const { name, url } = props
    return <Link to={url}>{name}</Link>
}

const PropertiesPage = (props: { user: UserInfoDto }) => {
    const [loading, setLoading] = React.useState(false)
    const [map, setMap] = React.useState<CarusoMap>()
    const [changeInFlight, setChangeInFlight] = React.useState<PropertyMapChange>()
    const [contextualizedMap, setContextualizedMap] = React.useState<MapWithContext>()
    const [showAddPropertyDialog, setShowAddPropertyDialog] = React.useState(false)
    const { propertyId } = useParams()
    const navigate = useNavigate()
    const selectedPropertyId = propertyId ? parseInt(propertyId) : undefined

    console.log("propertyId is", propertyId)

    const composites = map?.properties ?? []

    console.log("composites are ", composites)

    const load = (onComplete: (() => void) | undefined = undefined) => {
        setLoading(true)

        get("/api/map", (status, text) => {
            if (status === 200) {
                setMap(JSON.parse(text))
                getContextualizedMap(withContext => {
                    setContextualizedMap(withContext)
                    setLoading(false)

                    onComplete && onComplete()
                })
            }
        })
    }

    const doChange = (change: PropertyMapChange) => {
        setLoading(true)
        setChangeInFlight(change)
        sendChange(change, () => {

            load(() => setChangeInFlight(undefined))
        })
    }

    React.useEffect(load, [])

    var content: any
    if (selectedPropertyId) {
        const selectedProperty = composites.find(p => p.id === selectedPropertyId)!!
        content = !contextualizedMap ? <Stack direction="row" spacing={4} style={{ margin: "40px" }}><Typography variant="subtitle1">Loading Fidel Data</Typography><CircularProgress /></Stack> : <PropertyEntry map={contextualizedMap} onChange={load} doChange={doChange} composite={selectedProperty} />
    } else {
        content = (<div style={{ margin: "5px", marginTop: "20px" }}>
            {showAddPropertyDialog && <AddPropertyDialog onChange={change => { doChange(change); setShowAddPropertyDialog(false) }} onClose={() => setShowAddPropertyDialog(false)} />}
            {loading && <CircularProgress />}
            {contextualizedMap && <Stack direction="row" spacing={3}>{contextualizedMap.context.orphanedFidelLocations.map(w => <Alert color="warning"><div style={{ whiteSpace: "pre-wrap" }}>Unmapped Fidel Location: {w.brandName} @ {w.address.address}</div></Alert>)}</Stack>}
            {(composites.length === 0) ? <div style={{ textAlign: "center", paddingTop: "100px" }}><CircularProgress /></div> : <>
                <ImageList >
                    <ImageListItem key="Subheader" cols={2}>
                        {/* <ListSubheader component="div">December</ListSubheader> */}
                    </ImageListItem>

                    {composites.map(composite => {
                        const handelSelection = () => {

                            navigate(`/admin/properties/${composite.id}`)
                        }
                        const property = composite
                        const name = property?.label
                        const imageUrl = property?.imageUrl
                        return (<ImageListItem style={{ cursor: "pointer" }} key={imageUrl} onClick={handelSelection}>
                            <img
                                src={`${imageUrl}?w=248&fit=crop&auto=format`}
                                srcSet={`${imageUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                alt={name}
                                loading="lazy"
                            />
                            <ImageListItemBar
                                title={name}
                                subtitle={property?.address}
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${name}`}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                }
                            />
                        </ImageListItem>)
                    })}
                </ImageList>

                <IconButton onClick={() => setShowAddPropertyDialog(true)}><AddIcon /></IconButton>
            </>
            }


        </div>)
    }

    return (<>
        {changeInFlight && <ProgressDialog />}
        {content}
    </>)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return <LoginScreen
        message="Sign-in using your @caruso.com address"
        loggedInContent={(user) => {
            if (!user.isInsider) {
                return <Stack spacing={5}>
                    <h1>Restricted</h1>
                    <Box style={{ textAlign: "center" }}>
                        <Alert color="warning" style={{ display: "inline-block", minWidth: "300px" }}>
                            This is just for caruso employees & such.
                        </Alert>
                    </Box>
                </Stack>
            } else {
                return (<>
                    <SlimHeader user={user}>
                        <Routes>
                            {/* <Route index element={} /> */}
                            <Route index path="*" element={<>
                                <RoutedTabs propertyName="tabName" tabs={{
                                    "properties-and-tenants": {
                                        name: "Properties & Tenants",
                                        content: () => <>
                                            <PropertiesPage user={user} />
                                        </>
                                    },
                                    "guests-and-residents": {
                                        name: "Guests & Residents",
                                        content: () => <>
                                            <ul>
                                                <li><NavigateLink name="Search" url="/admin/member-search" /></li>
                                                <li><NavigateLink name="Test Accounts" url="/admin/test-accounts" /></li>
                                                <li><NavigateLink name="SMS Testing" url="/admin/test-sms" /></li>
                                            </ul>
                                        </>
                                    },
                                    "tags": {
                                        name: "Tags",
                                        content: () => <TagPickupsAdminPage user={user} />
                                    },
                                    "referrers":{
                                        name:"referrers",
                                        content: ()=> <ReferralTagsPage/>
                                    },
                                    "deep-links":{
                                        name:"DeepLinks",
                                        content: ()=> <Stack spacing={3}><DeepLinksListing baseUrl="https://carusosignature.com"/></Stack>
                                    },
                                    "accounting": {
                                        name: "Accounting",
                                        content: () => <AccountingReport />
                                    },
                                    "documentation": {
                                        name: "Documentation",
                                        content: () => <div style={{ padding: "20px" }}><MarkdownPage path="/segment-authoring-cheatsheet.md" /></div>
                                    },
                                    "api": {
                                        name: "API",
                                        content: () => <>
                                            <Stack spacing={4}>
                                                <Typography variant="h5">External API Events</Typography>
                                                <TheEventsViewer memberLinkMaker={memberId=><Link target="_blank" to={`/admin/members/${memberId}`}>{memberId}</Link>}/>
                                            </Stack>
                                        </>
                                    },
                                    "data": {
                                        name: "Data",
                                        content: () => <>

                                            <Grid container spacing={6} style={{ padding: "20px" }}>
                                                <Grid item xs={12} md={6}>
                                                    <Stack spacing={4}>
                                                        <LinkBox name="API Events" >
                                                            <Box style={{margin:"20px"}}/>
                                                            <Link to="/admin/events">API Events Console</Link>
                                                        </LinkBox>
                                                        <LinkBox name="Stable Data Sets" >
                                                            <Box style={{margin:"20px"}}/>
                                                            <DataSetListing/>
                                                        </LinkBox>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <LinkBox name="Exports" >
                                                        <Stack spacing={2} style={{marginTop:"10px"}}>
                                                            <Alert color="info">NOTE: Exports may take a few seconds to begin downloading</Alert>
                                                            <Typography>General</Typography>
                                                            <ExportLink name="Members By Date Created (So Cal)" url="/api/dumps/members-by-date" />
                                                            <ExportLink name="Voucher Uses" url="/api/dumps/voucher-uses.csv" />
                                                            <ExportLink name="Offer Code Consumption" url="/api/code-redemptions.csv" />
                                                            <ExportLink name="Anonymized Member Info" url="/api/dumps/anonymized-members.csv" />
                                                            <ExportLink name="Visitor Orphans" url="/api/dumps/visitor-orphans.csv" />
                                                            
                                                            <Divider/>
                                                            <Typography>Pickup Tags</Typography>
                                                            <ExportLink name="Pickups" url="/api/tag-pickups.csv" />
                                                            <ExportLink name="Scans" url="/api/dumps/tag-scans.csv" />
                                                            <Divider/>
                                                            <Typography>Fidel</Typography>
                                                            <ExportLink name="Transaction Counts By Card Type - Last 30 Days" url="/api/dumps/fidel-transaction-counts-by-card-type-last-30-days.csv" />
                                                            <Divider/>
                                                            <Typography>Parking</Typography>
                                                            <ExportLink name="Summary - Regular Members" url="/api/parking/usage.csv" />
                                                            <ExportLink name="Summary - Test Members" url="/api/parking/usage.csv?group=test-members" />
                                                            <ExportLink name="All Exits" url="/api/parking/exits.csv" />
                                                            <ExportLink name="Exit Segments" url="/api/parking/exit-segmentation.csv" />
                                                            <ExportLink name="Unlimited Parking Users" url="/api/parking/unlimited-users.csv" />
                                                        </Stack>
                                                    </LinkBox>
                                                </Grid>
                                            </Grid>
                                        </>
                                    },
                                    
                                    "links": {
                                        name: "Links",
                                        content: () => <>
                                            <Grid container spacing={2} style={{ padding: "20px" }}>
                                                <Grid item xs={12} sm={6}>
                                                    <LinkBox name="Consumer Experiences" markdown={`
                                                        - The Caruso Signature App
                                                            - [Web](https://carusosignature.com/)
                                                            - [IOS](https://apps.apple.com/us/app/caruso-signature/id1634031486)
                                                            - [Android](https://play.google.com/store/apps/details?id=com.caruso.hybrid&ref=apkcombo.com&pli=1)
                                                        - [Caruso Parking](https://carusoparking.com/)
                                                        - [Reward/Code Redemption](/codes/all)`} />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <LinkBox name="Dashboards">
                                                        <ul>
                                                            <li><a href="/admin/dashboards/default">Domo Dashboards</a></li>
                                                            <li><a href="https://caruso.retool.com/apps/ac55c1c0-bc75-11ed-8950-fbf4b88e79ab/Loyalty/LoyaltyDashboard_latest">Loyalty Dashboard (Retool)</a></li>
                                                            <li><a href="https://cust1240.cheetahedp.com/admin/sign_in/?stellar">Cheetah Dashboard</a></li>
                                                        </ul>
                                                    </LinkBox>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>

                                                    <LinkBox name="Tools" markdown={`
                                                        - [Cheetah EDP/CDP UI](https://cust1240.cheetahedp.com/admin/sign_in/?stellar)
                                                        - [Wayin (*Cheetah/Marigold Experiences platform*)](https://us-app.wayin.com/)
                                                        - [Fidel (*card-linked transactions*)](https://dashboard.fidel.uk)`} />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <LinkBox name="Misc." markdown={`
                                                        - [Software Development Backlog](https://plan.appfoo.io/)
                                                        - [Loyalty Sharepoint](https://carusomanagement.sharepoint.com/sites/CarusoLoyalty/Shared%20Documents/Forms/AllItems.aspx?e=5%3Aa51ff1d548744cfc944e40f277acf355&at=9&CT=1676786422484&OR=OWA%2DNT&CID=18f10122%2D0543%2Da4f0%2Db41e%2Deda5623500b6&RootFolder=%2Fsites%2FCarusoLoyalty%2FShared%20Documents%2FGeneral%2FCaruso&FolderCTID=0x0120004920A40538B7E146A525DC110EA8A905)
                                                        `} />
                                                </Grid>
                                            </Grid>
                                        </>
                                    },

                                }} />
                            </>
                            } />
                            <Route path="properties/:propertyId" element={<PropertiesPage user={user} />} />
                            <Route path="tags/:tagId" element={<SingleTagAdminPage user={user} />} />
                            <Route path="test-accounts" element={<TestAccountsPage />} />
                            <Route path="test-sms" element={<TestSMSPage />} />
                            <Route path="members/:memberId" element={<MemberAccountPage />} />
                            <Route path="members/:memberId/*" element={<MemberAccountPage />} />
                            <Route path="member-search/:query" element={<ActivityPage />} />
                            <Route path="member-search" element={<ActivityPage />} />
                            <Route path="dashboards/:dashboardId" element={<DomoDashboardsPage />} />
                            <Route path="data-documentation" element={<DataApp />} />
                        </Routes>
                    </SlimHeader>
                </>)
            }
        }
        } />
}

import { Link } from "@mui/material"
import { ActivityListItem } from "./api"

const noteworthyStatuses = new Set(["approved", "completed"])
// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { activity: ActivityListItem, onClick: () => void }) => {
    const { activity, onClick } = props

    let label:string
    if(activity.details.sl_type === "sl_purchase" && (activity.details.sl_total ?? 0) < 0){
        label = "Purchase Reversal"
    }else{
        label = activity.type_label
    }

    return <Link style={{ cursor: "pointer" }} onClick={onClick}>{label} {(activity.details.sl_status && !noteworthyStatuses.has(activity.details.sl_status)) && `(${activity.details.sl_status})`}</Link>

}
import { Alert, Button, CircularProgress } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from "react";
import { doExport } from "./exports";


// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { label?:string, name: string, url: string }) => {
    const { name, url } = props

    const label = props.label ?? props.name

    const [isWorking, setWorking] = useState(false)
    const [error, setError] = useState<string>()

    const downloadToFile = (downloadUrl:string)=>{
        const link = document.createElement("a");
        link.download = name;
        link.href = downloadUrl;
        link.click();
    }

    const onStart = () => {
        setWorking(true)
        doExport(url)
            .then(downloadToFile)
            .catch(error=>setError(`${error}`))
            .finally(()=>setWorking(false))
    };

    if(isWorking){
        return <CircularProgress/>
    }else{
        return (<>
            {error && <Alert color="error" onClose={()=>setError(undefined)}>{error}</Alert>}
            <Button variant="outlined" onClick={onStart} endIcon={<DownloadIcon />}>
                {label}
            </Button>
        </>)
    }
}
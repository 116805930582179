import { Button, CircularProgress, Divider, Stack, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { MemberSearchResult } from "./api"
import MemberInfoPanel from "./MemberInfoPanel"
import { useNavigate, useParams } from "react-router-dom"
import { searchMembers } from "./api-actions"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const {query} = useParams()
    const [t, setT] = useState(new Date().getTime())
    const [searchTerm, setSearchTerm] = useState(query ?? "")
    const [searching, setSearching] = useState(false)
    const [members, setMembers] = useState<MemberSearchResult[]>()
    const navigate = useNavigate()

    const doSearch = () => {
        if(query){
            setSearching(true)
            searchMembers(query, results => {
                setSearching(false)
                setMembers(results)
            })
        }
    }

    useEffect(doSearch, [query, t])

    const trigerSearch = ()=>{
        setT(new Date().getTime())
        navigate(`/admin/member-search/${encodeURIComponent(searchTerm)}`)
    }
    
    return (<Stack style={{ margin: "20px" }} spacing={2}>
        <TextField
            label="Search Email/MemberId/Name"
            value={searchTerm}
            onKeyDown={e=>{
                console.log("Pressed", e.keyCode)
                if(e.keyCode === 13){
                    trigerSearch()
                }
            }}
            onChange={e => setSearchTerm(e.target.value)} />
        {!searching && <Button variant="contained" onClick={trigerSearch}>Search</Button>}
        {searching && <CircularProgress />}
        {members?.map(member => {
            return (<>
                <Divider />
                <MemberInfoPanel member={member} onSelect={() => navigate(member.member_id)} />
            </>)
        })}
    </Stack>)
}
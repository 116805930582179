import { FormControl, InputLabel, Select, SelectProps } from "@mui/material"

export interface LabeledSelectProps<T>  {
    labelId: string
    label: string
    fullWidth?: boolean
}

// eslint-disable-next-line import/no-anonymous-default-export
export default <T,>(props: LabeledSelectProps<T> & SelectProps<T>) => {
    return (<FormControl fullWidth={props.fullWidth === undefined ? true : props.fullWidth}>
        <InputLabel required={props.required}>{props.label}</InputLabel>
        <Select
            {...props}
            labelId={props.labelId}
            label={props.label}>
            {props.children}
        </Select>
    </FormControl>)
}
import * as React from 'react';
// import { CoinsTagInfo, IRLTagInfo, OfferTagInfo } from '../shared/api';
import { CoinsTagInfo, IRLTagInfo, InPersonFulfillmentTagInfo, OfferTagInfo, SignupTagInfo } from './api';
import { Alert, Button, CircularProgress, Stack, Typography } from '@mui/material';
import SimpleMarkdown from './SimpleMarkdown';

interface LandingExperience {
    title:string,
    details:string,
    nextUrl:string
}

interface UserData {
    member_id: string
}
export interface TagAndMember {
    memberId:string, 
    tagId:string
}

interface AppTagScanPageProps {
    tagId: string
    submitTagAccept:(t:TagAndMember)=>Promise<Response>
    fetchProfile:()=>Promise<Response>
    fetchTagConfig:(t:TagAndMember)=>Promise<Response>
    navigateAwayLeavingNoTraceInHistory: (destination: string) => void
}

const fakeDescription = `
Action. Comedy. Drama. Fantasy. All your favorites are here.

Redeem this reward for two tickets and head to the big screen for an unforgettable moviegoing experience. Be sure to grab some buttery AMC Perfectly Popcorn™ for the show!

Available Locations:
AMC The Americana at Brand 18
AMC The Grove 14

How it works:
Present this digital voucher to the Concierge in order to pick up your 2 AMC tickets. The physical tickets can be used at the time of payment at AMC via AMC Theatre's standard ticketing process.
    
    `
const fakeInfo = {
    name: "Two Movie Tickets",
    description: fakeDescription,
    styledDescription:{
        markdown:fakeDescription
    },
    imageUrl: "https://s3.us-east-1.amazonaws.com/stellar-cust1240-kw07n8b8u71on066wh9g/contents/images/000/000/072/original/Movie_Tickets_-_1920x1200_%282%29.png?1659040830",
}

const fakeTagOfUnknownType: IRLTagInfo = {
    ...fakeInfo,
    type: "fdsafdsa"
}

const fakeTag: OfferTagInfo = {
    ...fakeInfo,
    type: "offer",
    acceptCode: "fdjifjdsaofjdsaio"
}


const centeredImageProps = (imageUrl: string): React.CSSProperties => {
    return {
        height: "150px",
        backgroundImage: `url("${imageUrl}")`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default (props: AppTagScanPageProps) => {
    const { tagId, fetchTagConfig, submitTagAccept, navigateAwayLeavingNoTraceInHistory, fetchProfile} = props
    const [isAccepting, setAccepting] = React.useState(false)
    const [error, setError] = React.useState<string>()
    const [user, setUser] = React.useState<UserData>()
    const [tagInfo, setTagInfo] = React.useState<IRLTagInfo>()

    const [landing, setLanding] = React.useState<LandingExperience>()

    const tagHandle = user &&  {
        memberId:user.member_id, 
        tagId:tagId
    }

    const busy = !user || !tagInfo

    React.useEffect(() => {

        fetchProfile()
            .then(async response => {
                const body = await response.text()
                if (response.status === 200) {
                    setUser(JSON.parse(body).payload?.data)
                } else {
                    setError(body)
                }
            })
            .catch(error => {
                setError(error?.payload?.error ?? "Unable to fetch redemption configuration");
            });
    }, [])

    React.useEffect(() => {
        if (tagHandle) {
            fetchTagConfig(tagHandle)
                .then(async response => {
                    const body = await response.text()
                    if (response.status === 200) {
                        setTagInfo(JSON.parse(body))
                    } else {
                        setError(body)
                    }
                })
                .catch(error => {
                    setError(error?.toSring() ?? "Unable to load tag");
                });
        }
    }, [user])

    const offerTag: OfferTagInfo | undefined = (tagInfo?.type === "offer") ? (tagInfo as OfferTagInfo) : undefined
    const coinTag: CoinsTagInfo | undefined = (tagInfo?.type === "coins") ? (tagInfo as CoinsTagInfo) : undefined
    const inPersonFulfillmentTag: InPersonFulfillmentTagInfo | undefined = (tagInfo?.type === "in-person-fulfillment") ? (tagInfo as InPersonFulfillmentTagInfo) : undefined
    const signupTag: SignupTagInfo | undefined = (tagInfo?.type === "signup") ? (tagInfo as SignupTagInfo) : undefined

    const doAccept = (onSuccess:()=>void) => {
        if (tagHandle) {
            console.log("Accepting it ...")
            setAccepting(true)
            submitTagAccept(tagHandle)
                .then(async response => {
                    const body = await response.text()
                    if (response.status === 200) {
                        onSuccess()
                    } else {
                        setAccepting(false)
                        setError(body)
                    }
                })
                .catch(error => {
                    setAccepting(false)
                    setError(error?.toString() ?? "Unable to fetch redemption configuration");
                });
        }
    }

    let acceptAction:(()=>void )|undefined = undefined
    if(offerTag){
        acceptAction = ()=>doAccept(()=>{
            navigateAwayLeavingNoTraceInHistory("/dashboard/manage-wallet") 
        })
    }else if(coinTag){
        acceptAction = ()=>doAccept(()=>{
            setLanding({
                title:"Your reward has been confirmed!",
                details:"Please allow a few minutes for your Caruso Coins to appear in your account.",
                nextUrl:"/dashboard/rewards",
            })
        })
    }

    const doCancel = () => {
        navigateAwayLeavingNoTraceInHistory("/dashboard")
    }

    const alertStyles: React.CSSProperties = {
        margin: "20px", maxWidth: "500px"
    }

    console.log("tagInfo", tagInfo)
    return (
        <Stack style={{ textAlign: "center" }}>
            {busy && <CircularProgress />}
            {error && <Alert style={alertStyles} color="error">{error}</Alert>}
            {(tagInfo && (!offerTag && !coinTag && !inPersonFulfillmentTag && !signupTag)) && <Alert style={alertStyles} color="warning">To fully interact with this tag, please update to the latest version of the Caruso Signature App.</Alert>}
            
            {(tagInfo && user) && <div>
                <Stack spacing={5}>
                    <div style={centeredImageProps(tagInfo.imageUrl)}></div>
                    <Stack spacing={5} style={{ margin: "20px" }}>
                        {landing && <>
                            <Typography variant={'h2'}>{landing.title}</Typography>
                            <Typography>{landing.details}</Typography>
                            <div>
                                <Stack direction="row-reverse">
                                    <Button 
                                        variant="contained"
                                        onClick={()=>navigateAwayLeavingNoTraceInHistory(landing.nextUrl)}>Continue</Button>
                                </Stack>
                            </div>
                        </>}
                        {!landing && <>
                            <h2>{tagInfo?.name}</h2>
                            <p style={{ textAlign: "left" }}><SimpleMarkdown>{tagInfo?.styledDescription?.markdown ?? ""}</SimpleMarkdown></p>
                            <div>
                                <Stack direction="row-reverse">
                                    {(acceptAction && isAccepting) && <CircularProgress />}
                                    {acceptAction && (
                                        <Button
                                            variant="contained"
                                            disabled={isAccepting}
                                            onClick={acceptAction}>Accept</Button>
                                    )}

                                    <Button onClick={doCancel}>Cancel</Button>
                                </Stack>
                            </div>
                        </>}

                    </Stack>

                </Stack>
            </div>}
        </Stack>
    )
}
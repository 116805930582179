import { Box,  Stack, Typography } from "@mui/material"
import { CSSProperties } from "react"
import { Member, MemberCardDto} from "./api"

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { member: Member, cardInfo: MemberCardDto }) => {
    const { member } = props
    const memberCard = props.cardInfo

    const email = member.email
    const phoneNumber = member.mobile_phone
    const memberClass = member.member_class_name
    const memberClassLabel = (!memberClass || memberClass.length === 0) ? undefined : memberClass
    const linkStyle: CSSProperties = {
        color: memberCard?.secondaryForegroundColor
    }

    return (<div
        style={{
            background: `url(${memberCard?.imageUrl})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "335px",
            height: "185px",
            textAlign: "right"
        }}>
        {/* <CardMedia
                sx={{ height: 140 }}
                image={`/api/members/${member?.member_id}/card-background`}
                title="green iguana"
            /> */}

        <Stack direction="row" spacing={2} style={{ margin: "20px", display: "inline-block" }} >
            {/* <Avatar /> */}
            <Stack>
                <Typography gutterBottom variant="h5" color={memberCard?.primaryForegroundColor} component="div">{member.first_name} {member.last_name}</Typography>
                <Typography variant="body2" color={memberCard?.secondaryForegroundColor}>
                    <Stack>
                        <Box><a href={`https://cust1240.cheetahedp.com/console/people/${member?.id}?_app_nav=loyalty`} style={linkStyle} target="_blank" rel="noreferrer">{member.member_id}</a></Box>
                        <Box>{email && <a href={`mailto:${email}`} style={linkStyle}>{email}</a>}</Box>
                        <Box>{phoneNumber && <a href={`tel:${phoneNumber}`} style={linkStyle}>{phoneNumber}</a>}</Box>
                    </Stack>
                </Typography>
            </Stack>
        </Stack>
    </div>)
}

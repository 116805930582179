import { useState, useEffect } from "react";
import { CarusoMap,  GenericReferer, Member, MemberReferer, ReferrerRef, TenantReferer } from "./api";
import { Box } from "@mui/material";
import { getMapPromise, getMemberPromise } from "./api-actions"
import { getTenant } from "./map";


export const visitReferrerRef = (value:ReferrerRef):ReferrerVisit => {
    return {
        memberRef : (value.type === "member") ? value as MemberReferer : undefined,
        tenantRef: (value.type === "tenant") ? value as TenantReferer : undefined,
        genericRef : (value.type === "generic") ? value as GenericReferer : undefined
    }
}
interface ReferrerVisit{
    memberRef: MemberReferer | undefined
    tenantRef: TenantReferer | undefined
    genericRef: GenericReferer | undefined
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props:{value:ReferrerRef, map?:CarusoMap})=>{
    const {value} = props
    const [map, setMap] = useState(props.map)
    const [member, setMember] = useState<Member>()
    const {memberRef, tenantRef, genericRef} = visitReferrerRef(value)

    useEffect(()=>{
        if(tenantRef && !map){
            getMapPromise().then(setMap)
        }
    }, [tenantRef])

    useEffect(()=>{
        if(memberRef){
            getMemberPromise(memberRef.memberId).then(setMember)
        }
    }, [memberRef])

    const tenantMatch = (map && tenantRef) && getTenant(tenantRef.tenantId, map)

    return (<Box style={{display:"inline-block"}}>
        {memberRef && `${member ? `${member.first_name} ${member.last_name} / ${member.email}` : ''} (member ${memberRef.memberId})`}
        {tenantRef && `${tenantMatch ? `${tenantMatch.tenant.name} @ ${tenantMatch.property.name}`: tenantRef.tenantId} (tenant)`}
        {genericRef && `${genericRef.discriminator} (generic referer)`}
    </Box>)
}

import { CircularProgress } from '@mui/material';
import React from 'react';
import { get } from './http';
import SimpleMarkdown from './SimpleMarkdown';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props:{path:string}) => {
    const {path} = props
    const [markdown, setMarkdown] = React.useState<string>()

    const getMarkdown = ()=> get(path, (status, text)=> {
        if(status === 200){
            setMarkdown(text)
        }else{
            setMarkdown(`There was an error loading the content from ${path}`)
        }
    })

    React.useEffect(getMarkdown, [])

    if(markdown === undefined){
        return <CircularProgress/>
    }else{
        return <SimpleMarkdown>{markdown}</SimpleMarkdown>
    }
}
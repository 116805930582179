import { ReactNode, useEffect, useState } from "react"
import { ActivityListItem, AdministrativeCoinsAdjustmentRequest, } from "./api"
import { postJson } from "./http"
import { Alert, Box, Button, CircularProgress, Stack, Typography } from "@mui/material"
import { ActivityList } from "./ActivityList"
import { getActivitiesForMemberOfType } from "./api-actions"

const FIX_ID="2024-05-OppositePurchaseReturnsFix-v1"
const FIX_LABEL="Automated Adjustment for Purchase Returns"

export const BadPurchaseReturnFix = (props: { memberId: string, setFocus: (a: ActivityListItem) => void }) => {
    const { memberId, setFocus } = props

    const [activitiesToFix, setActivitiesToFix] = useState<ActivityListItem[]>()
    const [fixes, setFixes] = useState<ActivityListItem[]>([])
    const [fixSubmitted, setFixSubmitted] = useState(false)
    const [lastFixCheck, setFixCheck] = useState<number>()

    const isWaitingForFix = (fixSubmitted && fixes.length === 0)

    console.log("isWaitingForFix?", isWaitingForFix)

    const totalFixAmount = Math.round(activitiesToFix?.reduce((accum, next) => {
        return accum + (Math.abs(next.details.sl_value ?? 0))
    }, 0) ?? 0);


    const refresh = (onComplete?:()=>void)=>{

        getActivitiesForMemberOfType(memberId, ['sl_purchase_return', 'sl_adjust_earn'], (activities, error) => {

            console.log("activities", activities)

            const badReturns = (activities ?? []).filter(a => {

                console.log(a.details.sl_type)

                const isReturn = a.details.sl_type === 'sl_purchase_return'

                const coinBalances = a.details.sl_balance['caruso_coin'] as number[]
                const [coinsBefore, coinsAfter] = coinBalances

                const gotMoreCoins = (coinsAfter > coinsBefore)

                return isReturn && gotMoreCoins
            })

            console.log("badReturns", badReturns)

            const fixes = (activities ?? []).filter(a=>{
                return a.details.sl_type === 'sl_adjust_earn' && a.details.sl_comment === FIX_ID
            })

            setActivitiesToFix(badReturns)
            setFixes(fixes)
            if(onComplete){
                onComplete()
            }
        })
    }

    useEffect(refresh, [])

    useEffect(()=>{
        console.log("Checking for fix")
        if(isWaitingForFix){
            refresh(()=>{
                setFixCheck(new Date().getTime())
            })
        }
    }, [lastFixCheck, fixSubmitted])

    const doFix = ()=>{
        const body:AdministrativeCoinsAdjustmentRequest = {
            numCoins: totalFixAmount * -1,
            label: FIX_LABEL,
            comment: FIX_ID,
        }
        if (memberId) {
            setFixSubmitted(true)
            postJson(`/api/members/${memberId}/adjustments`, JSON.stringify(body), (status, body) => {})
        }
    }

    return (<>
        {!activitiesToFix ? <CircularProgress /> : <>
            {activitiesToFix.length === 0 ? <Alert color="success">There are no matching activities to fix</Alert> : <>

                {isWaitingForFix ? <CircularProgress/> : <>
                    {(fixes?.length > 0) ? <Alert>This fix has been applied</Alert>: <>
                        <Alert color="warning">This will add a -{totalFixAmount} coins adjustment to compensate for the following {activitiesToFix.length} activities</Alert>
                        <Button color="warning" variant="contained" onClick={doFix}>Apply Fix</Button>
                    </>}
                    <ActivityList
                        activitiesToShow={fixes.concat(activitiesToFix)}
                        setFocus={setFocus}
                    />
                </>}
            </>}
        </>}
    </>)

}

export const ParkingFix = (props: { memberId: string, onComplete: () => void }) => {
    const { memberId, onComplete } = props
    const [isWorking, setWorking] = useState(false)

    const doParkingFix = () => {
        if (memberId) {
            setWorking(true)
            postJson(`/api/members/${memberId}/parking/reset`, "", (status, body) => {
                if (status === 200) {
                    alert(`Success: ${body}`)
                } else {
                    alert(`Failed: ${body}`)
                }
                onComplete()
            })
        }
    }

    return (<>
        <Alert color="info">
            This will reset the link between this Caruso Signature account and the "Tech Solutions" parking API
        </Alert>
        {isWorking ? <CircularProgress /> : <Button variant="contained" onClick={doParkingFix}>Reset Parking Account</Button>}
    </>)
}

export const MemberFixesTab = (props: { memberId: string, setFocus: (a: ActivityListItem) => void }) => {
    const { memberId, setFocus } = props

    const [activeFix, setActiveFix] = useState<string>()

    const reset = () => setActiveFix(undefined)

    const fixes: Record<string, () => ReactNode> = {
        "Reset Parking Account": () => <ParkingFix memberId={memberId} onComplete={reset} />,
        "Adjust Bad Reversals": () => <BadPurchaseReturnFix memberId={memberId} setFocus={setFocus} />
    }

    return (<>
        <Box style={{ textAlign: "center" }}>
            {activeFix && <>
                <Stack spacing={4}>
                    <Typography variant={"h4"}>{activeFix}</Typography>
                    {fixes[activeFix]()}
                </Stack>
            </>}
            {!activeFix && <>

                <Stack spacing={4} style={{marginTop:"20px"}}>
                    {Object.keys(fixes).map(fixName => <Box><Button variant="outlined" onClick={() => setActiveFix(fixName)}>{fixName}...</Button></Box>)}
                </Stack>
            </>}

            {/* <Button variant="outlined" onClick={doParkingFix}>Reset Parking Account</Button>
            <Button variant="outlined" onClick={adjustBadReversals}>Adjust Bad Reversals</Button> */}

        </Box>
    </>)
}
import { useState, useEffect } from "react";
import { CarusoMap,  GenericReferer, MemberReferer, ReferrerRef, TenantReferer } from "./api";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, Stack, TextField } from "@mui/material";
import LabeledSelect from "./LabeledSelect";
import { MemberSearchResult } from "./api"
import { getMapPromise, searchMembers } from "./api-actions"
import { TenantAndProperty, flattenTenants } from "./map";
import ReferrerLabel, { visitReferrerRef } from "./ReferrerLabel";


const MemberSearch = (props:{value:MemberReferer|undefined, onSelect:(m:MemberReferer)=>void, })=>{
    const {value, onSelect, } = props
    const [t, setT] = useState(new Date().getTime())
    const [searchTerm, setSearchTerm] = useState(value?.memberId ?? "")
    const [searching, setSearching] = useState(false)
    const [members, setMembers] = useState<MemberSearchResult[]>()

    const doSelect = (m:MemberSearchResult)=>{
        const mRef:MemberReferer = {
            type:"member",
            memberId: m.member_id,
        }
        onSelect(mRef)
    }

    const doSearch = () => {
        if(searchTerm){
            setSearching(true)
            searchMembers(searchTerm, results => {
                setSearching(false)
                setMembers(results)
            })
        }
    }

    useEffect(doSearch, [searchTerm, t])

    const trigerSearch = ()=>{
        setT(new Date().getTime())
    }
    
    return (<Stack spacing={2}>
        <TextField
            label="Search Email/MemberId/Name"
            value={searchTerm}
            onKeyDown={e=>{
                console.log("Pressed", e.keyCode)
                if(e.keyCode === 13){
                    trigerSearch()
                }
            }}
            onChange={e => setSearchTerm(e.target.value)} />
        {/* {!searching && <Button variant="contained" onClick={trigerSearch}>Search</Button>} */}
        {searching && <CircularProgress />}
        {members?.map(member => {
            return (<>
                <Divider />
                <Button onClick={()=>doSelect(member)}>{member.member_id}</Button> {member.first_name} {member.last_name} {member.email}
                {/* <MemberInfoPanel member={member} onSelect={() => navigate(member.member_id)} /> */}
            </>)
        })}
    </Stack>)
}

const TenantSearch = (props:{value:TenantReferer|undefined, onSelect:(m:TenantReferer)=>void, })=>{
    
    const {value, onSelect, } = props
    const [t, setT] = useState(new Date().getTime())
    const [searchTerm, setSearchTerm] = useState("")
    const [searching, setSearching] = useState(false)
    const [map, setMap] = useState<CarusoMap>()

    const allTenants:TenantAndProperty[] | undefined = map && flattenTenants(map)

    const searchTermLower = searchTerm.toLowerCase()
    const results = allTenants?.filter(({tenant, property})=>{
        const tenantMatch = tenant.name.toLowerCase().indexOf(searchTermLower) !== -1
        const propertyMatch = property.name.toLowerCase().indexOf(searchTermLower) !== -1
        return tenantMatch || propertyMatch
    })

    useEffect(()=>{
        getMapPromise()
            .then(setMap)
    }, [])

    const doSelect = (v:TenantAndProperty)=>{
        const ref:TenantReferer = {
            type:"tenant",
            tenantId: v.tenant.id,
        }
        onSelect(ref)
    }

    const doSearch = () => {
        if(searchTerm){
            
        }
    }

    useEffect(doSearch, [searchTerm, t])

    const trigerSearch = ()=>{
        setT(new Date().getTime())
    }
    
    return (<Stack spacing={2}>
        {map && <>
            
        <TextField
            label="Search"
            value={searchTerm}
            onKeyDown={e=>{
                console.log("Pressed", e.keyCode)
                if(e.keyCode === 13){
                    trigerSearch()
                }
            }}
            onChange={e => setSearchTerm(e.target.value)} />
        </>}
        {(!map || searching) && <CircularProgress />}
        {results?.map(result => {
            return (<>
                <Divider />
                <Box><Button style={{display:"inline-block", textAlign:"left"}} onClick={()=>doSelect(result)}>{result.tenant.name} @ {result.property.name}</Button> </Box>
            </>)
        })}
    </Stack>)
}



const ReferrerSelectDialog = (props:{existing?:ReferrerRef, onClose:()=>void, onComplete:(newReferrer:ReferrerRef|undefined)=>void})=>{
    const {existing, onClose, onComplete} = props
    const [isWorking, setWorking] = useState(false)
    const [discriminator, setDescriminator] = useState("")
    const [type, setType] = useState(existing?.type ?? "None")
    

    const doSaveGeneric = ()=>{
        const m:GenericReferer = {
            type:"generic",
            discriminator: discriminator
        }
        onComplete(m)
    }

    const existingVisit = existing && visitReferrerRef(existing)

    return <Dialog open={true} onClose={onClose}
        fullWidth
        maxWidth="sm">
        <DialogTitle>Select Referrer</DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
            <Stack spacing={2}>

                <LabeledSelect labelId = "refType" label="Type" value={type} onChange={(e)=>setType(e.target.value)}>
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="member">Member</MenuItem>
                    <MenuItem value="tenant">Tenant</MenuItem>
                    <MenuItem value="generic">Generic</MenuItem>
                </LabeledSelect>

                {type === "member" && <>
                    <MemberSearch value={existingVisit?.memberRef} onSelect={mr=>onComplete(mr)}/>
                </>}

                {type === "tenant" && <>
                    <TenantSearch value={existingVisit?.tenantRef} onSelect={onComplete}/>
                </>}

                {type === "generic" && <>
                    <TextField
                        required
                        value={discriminator}
                        onChange={e => setDescriminator(e.target.value)}
                        label="Discriminator" />
                </>}

            </Stack>
        </DialogContent>
        <DialogActions>
            <Box style={{textAlign:"right", minHeight:"100px", width:"100%"}}>

                {!isWorking && <>
                    <Button onClick={onClose}>Cancel</Button>
                    {(type === "generic") && <Button variant="contained" onClick={doSaveGeneric}>Select</Button>}
                    {(type === "None") && <Button variant="contained" onClick={()=>onComplete(undefined)}>Select</Button>}
                </>}
                {isWorking && <Box style={{ width:"50px", height:"50px"}}><CircularProgress style={{width:"50px", height:"50px"}}/></Box>}
            </Box>
        </DialogActions>

    </Dialog>
}


export const ReferrerSelect = (props:{value:ReferrerRef|undefined, onChange:(newValue:ReferrerRef|undefined)=>void})=>{
    const {value, onChange} = props
    const [showDialog, setShowDialog] = useState(false)

    const doSelect = ()=> setShowDialog(true)

    return (<>
    {showDialog && <ReferrerSelectDialog 
        existing={value}
        onComplete={(r)=>{
            onChange(r)
            setShowDialog(false)
        }}
        onClose={()=>setShowDialog(false)}
        />}
        <Box style={{display:"inline-block"}}><Button onClick={doSelect}>{value ? <ReferrerLabel value={value}/> : "none"}</Button></Box>
    </>)
}
import { Box } from "@mui/material"
import { Member, MemberSearchResult } from "./api"
import { Link } from "react-router-dom"


// eslint-disable-next-line import/no-anonymous-default-export
export default (props:{member:MemberSearchResult|Member, onSelect:()=>void})=>{
    const {member, onSelect} = props

    const mobile_phone:string|undefined = (member as any)?.mobile_phone

    return <>
        <Box><Link  to={`/admin/members/${member.member_id}`} style={{ cursor: "pointer" }} >{member.member_id}</Link></Box>
        <Box>{member.first_name} {member.last_name}</Box>
        <Box><a href={`mailto:${member.email}`}>{member.email}</a></Box>
        {mobile_phone && <Box><a href={`tel:${mobile_phone}`}>{mobile_phone}</a></Box>}
    </>
}
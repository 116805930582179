

import React, { ReactNode } from "react";
import { Box, Stack, Tab, Tabs } from "@mui/material";


// eslint-disable-next-line import/no-anonymous-default-export
export default (props:{tabs:Record<string, ReactNode>})=>{

    const [value, setValue] = React.useState(0);
  
    const tabName = Object.keys(props.tabs)[value]
  
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      console.log("Seleted", newValue)
      setValue(newValue);
    };
  
  
    function a11yProps(index: string) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }
  
    return (    <Stack sx={{ width: '100%' }} spacing={3}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        {Object.keys(props.tabs).map((name)=>{
          return <Tab key={name} label={name} {...a11yProps(name)} />
        })}
      </Tabs>
    </Box>
    {
      props.tabs[tabName]
    }
  </Stack>)
  }
import { ReactMarkdown, ReactMarkdownOptions} from "react-markdown/lib/react-markdown"
import { minusIndent } from "./Util"
import rehypeRaw from "rehype-raw";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props:ReactMarkdownOptions)=>{
    const {children} = props


    // See https://github.com/orgs/rehypejs/discussions/63
    // @ts-expect-error
    return <ReactMarkdown rehypePlugins={[rehypeRaw]} {...props}>{minusIndent(children ?? "")}</ReactMarkdown>
}

import React from "react"
import { CarusoMapProperty, PropertyUpdate } from "./api"
import { Alert, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material"
import { get } from "./http"

export interface WinteamThing {
    id: string
}
export const getWinteamIds = (onResults: (c: WinteamThing[] | undefined) => void) => {
    get("/api/winteam/locations", (status, text) => {
        if (status === 200) {
            onResults(JSON.parse(text))
        } else {
            onResults(undefined)
        }
    })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { property: CarusoMapProperty, onChange: (update: PropertyUpdate) => void, onClose: () => void }) => {
    const { property, onChange, onClose } = props

    const [isWorking, setWorking] = React.useState(false)
    const [name, setName] = React.useState<string>(property.name)
    const [address, setAddress] = React.useState<string>(property.address ?? "")
    const [label, setLabel] = React.useState<string>(property.label ?? "")
    const [url, seturl] = React.useState<string>(property.url ?? "")
    const [winteamId, setwinteamId] = React.useState<string>(property.winteamId ?? "")
    const [zipCode, setzipCode] = React.useState<string>(property.zipCode ?? "")
    const [error, setError] = React.useState<string | undefined>(undefined)
    const [isLoyalty, setLoyalty] = React.useState<boolean>(property.loyaltyProgram?.isActive ?? true)
    const [winteamids, setwinteamids] = React.useState<WinteamThing[]>()


    const undefinedIfBlank = (s:string|undefined ):string|undefined => (s && s.trim().length === 0) ? undefined : s?.trim()


    const doSave = () => {
        setWorking(true)
        setError(undefined)
        onChange({
            type: "property-update",
            propertyId: property.id,
            name: name,
            disclaimer: property.disclaimer,
            zipCode: zipCode,
            imageUrl: property.imageUrl,
            address: undefinedIfBlank(address),
            label: undefinedIfBlank(label),
            url: undefinedIfBlank(url),
            winteamId: undefinedIfBlank(winteamId),
            loyaltyProgram: isLoyalty ? {isActive:true} : {isActive:false}
        })
    }

    React.useEffect(() => {
        getWinteamIds(ids => setwinteamids(ids))
    }, [])

    return <Dialog open={true} onClose={onClose}
        fullWidth
        maxWidth="sm">
        <DialogTitle>Edit Property</DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
            <Stack spacing={2}>
                {error ? <Alert color="warning">{error}</Alert> : null}
                <TextField
                    required
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label="Name" />

                <TextField
                    required
                    value={label}
                    onChange={e => setLabel(e.target.value)}
                    label="Label" />

                <TextField
                    required
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    label="Address" />

                <TextField
                    required
                    value={zipCode}
                    onChange={e => setzipCode(e.target.value)}
                    label="Zip" />


                <TextField
                    required
                    value={url}
                    onChange={e => seturl(e.target.value)}
                    label="URL" />

                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">WinteamID</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={winteamId}
                        label="WinteamID"
                        onChange={e => setwinteamId(e.target.value)}
                    >
                        <MenuItem value={""}>none</MenuItem>
                        {((winteamids?.length ?? 0) && winteamId) == 0 && [<MenuItem value={winteamId}>{winteamId}</MenuItem>]}
                        {(winteamids?.length ?? 0) == 0 && <MenuItem disabled={true} value={undefined}>discovering available IDs...</MenuItem>}
                        {winteamids && winteamids.map(l => {
                            return <MenuItem value={l.id}>{l.id}</MenuItem>
                        })}
                    </Select>
                </FormControl>

                <FormControlLabel control={<Checkbox
                            checked={isLoyalty}
                            onChange={e => setLoyalty(e.target.checked)} />} label="Show in Loyalty Program" />
            </Stack>
        </DialogContent>
        <DialogActions>
            {isWorking ? <CircularProgress /> : <>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" disabled={!(name && address)} onClick={doSave}>Save</Button>
            </>}
        </DialogActions>

    </Dialog>
}


import React, { ReactNode } from "react";
import { get } from "./http";
import { Alert, Box, Button, CircularProgress, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from "dayjs";
import { doExport } from "./exports";
import ExportLink from "./ExportLink";

const padZeros = (n:number, len:number):string => {
    let str = n.toString()
    while(str.length < len){
        str = "0" + str
    }
    return str
}

const format = (d:JsonLocalDate) => [d.year, d.month, d.day].map(n => padZeros(n, 2)).join("-")


interface JsonLocalDate {
    year:number
    month:number
    day:number
}
interface PeriodCoinUsage{
     firstDay:JsonLocalDate,
     lastDay:JsonLocalDate,
     additions:number,
     redemptions:number,
     expirations:number
}

interface OfferInfo{
    id:number
    name:string
    label:string
}

interface OfferLedgerChanges {
    numNew:number
    numCancelled:number
    numConsumed:number
    numExpired:number
}

interface PeriodOfferUsage {
    firstDay:JsonLocalDate,
    lastDay:JsonLocalDate,
    changesByOfferId:Record<string, OfferLedgerChanges>
}

interface ReportResults{
    offers:OfferInfo[]
    offerUsages:PeriodOfferUsage[]
    coinUsages:PeriodCoinUsage[]
}

interface ReportResultsByTestStatus{
  normal:ReportResults
  test:ReportResults
}


const dummyResults:ReportResultsByTestStatus = {
  "normal" : {
    "offers" : [ {
      "id" : 1,
      "name" : "restaurant_voucher_10",
      "label" : "Restaurant Voucher - $10 "
    }, {
      "id" : 4,
      "name" : "free_parking",
      "label" : "Free Parking"
    }, {
      "id" : 7,
      "name" : "private_event_space_coast_lounge",
      "label" : "Private Event Space (Coast Lounge)"
    }, {
      "id" : 15,
      "name" : "movie_tickets_2",
      "label" : "Movie Tickets (2)"
    }, {
      "id" : 17,
      "name" : "santa_house_fast_pass",
      "label" : "Santa House Fast Pass 12/24"
    }, {
      "id" : 22,
      "name" : "restaurant_voucher_10_welcome",
      "label" : "Restaurant Voucher - $10 - Welcome"
    }, {
      "id" : 25,
      "name" : "restaurant_voucher_10_birthday",
      "label" : "Restaurant Voucher - $10 - Birthday"
    }, {
      "id" : 37,
      "name" : "restaurant_voucher_10_test_1",
      "label" : "Restaurant Voucher - $25"
    }, {
      "id" : 38,
      "name" : "restaurant_voucher_15_welcome_test",
      "label" : "Restaurant Voucher - $15 - Welcome"
    }, {
      "id" : 40,
      "name" : "restaurant_voucher_50_test_1_2",
      "label" : "Restaurant Voucher - $50"
    }, {
      "id" : 41,
      "name" : "private_event_space_great_room_test_1",
      "label" : "Private Event Space (Great Room)"
    }, {
      "id" : 42,
      "name" : "restaurant_voucher_75_test_1",
      "label" : "Restaurant Voucher - $75"
    }, {
      "id" : 44,
      "name" : "restaurant_voucher_100_test_1_2",
      "label" : "Restaurant Voucher - $100"
    }, {
      "id" : 45,
      "name" : "miramar_hotel_stay_low_season",
      "label" : "Miramar Hotel Stay (Low Season)"
    }, {
      "id" : 49,
      "name" : "lny_amass_discount",
      "label" : "LNY Amass Discount"
    }, {
      "id" : 50,
      "name" : "unlimited_free_parking",
      "label" : "Unlimited Free Parking"
    }, {
      "id" : 51,
      "name" : "free_parking_1",
      "label" : "One Day Self-Parking"
    } ],
    "offerUsages" : [ {
      "firstDay" : {
        "year" : 2022,
        "month" : 1,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2022,
        "month" : 9,
        "day" : 30
      },
      "changesByOfferId" : {
        "22" : {
          "numNew" : 263,
          "numCancelled" : 0,
          "numConsumed" : 5,
          "numExpired" : 0
        },
        "15" : {
          "numNew" : 3,
          "numCancelled" : 0,
          "numConsumed" : 3,
          "numExpired" : 0
        },
        "41" : {
          "numNew" : 3,
          "numCancelled" : 0,
          "numConsumed" : 3,
          "numExpired" : 0
        },
        "7" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "37" : {
          "numNew" : 3,
          "numCancelled" : 0,
          "numConsumed" : 2,
          "numExpired" : 0
        },
        "1" : {
          "numNew" : 4,
          "numCancelled" : 0,
          "numConsumed" : 1,
          "numExpired" : 0
        },
        "25" : {
          "numNew" : 14,
          "numCancelled" : 0,
          "numConsumed" : 3,
          "numExpired" : 0
        }
      }
    }, {
      "firstDay" : {
        "year" : 2022,
        "month" : 10,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2022,
        "month" : 12,
        "day" : 31
      },
      "changesByOfferId" : {
        "22" : {
          "numNew" : 2522,
          "numCancelled" : 0,
          "numConsumed" : 77,
          "numExpired" : 0
        },
        "25" : {
          "numNew" : 932,
          "numCancelled" : 0,
          "numConsumed" : 31,
          "numExpired" : 597
        },
        "1" : {
          "numNew" : 10,
          "numCancelled" : 0,
          "numConsumed" : 5,
          "numExpired" : 0
        },
        "15" : {
          "numNew" : 3,
          "numCancelled" : 0,
          "numConsumed" : 3,
          "numExpired" : 0
        },
        "17" : {
          "numNew" : 7,
          "numCancelled" : 0,
          "numConsumed" : 6,
          "numExpired" : 1
        },
        "44" : {
          "numNew" : 4,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "37" : {
          "numNew" : 2,
          "numCancelled" : 0,
          "numConsumed" : 1,
          "numExpired" : 0
        },
        "4" : {
          "numNew" : 7,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 7
        },
        "7" : {
          "numNew" : 0,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 1
        },
        "42" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 1,
          "numExpired" : 0
        }
      }
    }, {
      "firstDay" : {
        "year" : 2023,
        "month" : 1,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2023,
        "month" : 3,
        "day" : 31
      },
      "changesByOfferId" : {
        "22" : {
          "numNew" : 1167,
          "numCancelled" : 0,
          "numConsumed" : 85,
          "numExpired" : 223
        },
        "25" : {
          "numNew" : 1668,
          "numCancelled" : 0,
          "numConsumed" : 35,
          "numExpired" : 970
        },
        "1" : {
          "numNew" : 31,
          "numCancelled" : 0,
          "numConsumed" : 10,
          "numExpired" : 0
        },
        "4" : {
          "numNew" : 34,
          "numCancelled" : 3,
          "numConsumed" : 0,
          "numExpired" : 33
        },
        "37" : {
          "numNew" : 19,
          "numCancelled" : 0,
          "numConsumed" : 13,
          "numExpired" : 0
        },
        "49" : {
          "numNew" : 35,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "40" : {
          "numNew" : 8,
          "numCancelled" : 0,
          "numConsumed" : 6,
          "numExpired" : 0
        },
        "42" : {
          "numNew" : 4,
          "numCancelled" : 0,
          "numConsumed" : 3,
          "numExpired" : 0
        },
        "44" : {
          "numNew" : 6,
          "numCancelled" : 0,
          "numConsumed" : 7,
          "numExpired" : 0
        },
        "15" : {
          "numNew" : 9,
          "numCancelled" : 0,
          "numConsumed" : 4,
          "numExpired" : 0
        },
        "38" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "50" : {
          "numNew" : 2,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 1
        }
      }
    }, {
      "firstDay" : {
        "year" : 2023,
        "month" : 4,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2023,
        "month" : 5,
        "day" : 16
      },
      "changesByOfferId" : {
        "22" : {
          "numNew" : 409,
          "numCancelled" : 66,
          "numConsumed" : 54,
          "numExpired" : 1521
        },
        "25" : {
          "numNew" : 1575,
          "numCancelled" : 1,
          "numConsumed" : 42,
          "numExpired" : 1399
        },
        "37" : {
          "numNew" : 19,
          "numCancelled" : 0,
          "numConsumed" : 15,
          "numExpired" : 0
        },
        "1" : {
          "numNew" : 62,
          "numCancelled" : 2,
          "numConsumed" : 18,
          "numExpired" : 0
        },
        "40" : {
          "numNew" : 10,
          "numCancelled" : 1,
          "numConsumed" : 6,
          "numExpired" : 0
        },
        "15" : {
          "numNew" : 19,
          "numCancelled" : 1,
          "numConsumed" : 5,
          "numExpired" : 0
        },
        "44" : {
          "numNew" : 11,
          "numCancelled" : 0,
          "numConsumed" : 7,
          "numExpired" : 0
        },
        "45" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 1,
          "numExpired" : 0
        },
        "4" : {
          "numNew" : 17,
          "numCancelled" : 4,
          "numConsumed" : 0,
          "numExpired" : 17
        },
        "50" : {
          "numNew" : 1,
          "numCancelled" : 1,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "51" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 1
        },
        "42" : {
          "numNew" : 4,
          "numCancelled" : 0,
          "numConsumed" : 5,
          "numExpired" : 0
        },
        "38" : {
          "numNew" : 67,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        }
      }
    } ],
    "coinUsages" : [ {
      "firstDay" : {
        "year" : 2022,
        "month" : 1,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2022,
        "month" : 9,
        "day" : 30
      },
      "additions" : 930176,
      "redemptions" : 60380,
      "expirations" : 0
    }, {
      "firstDay" : {
        "year" : 2022,
        "month" : 10,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2022,
        "month" : 12,
        "day" : 31
      },
      "additions" : 18749440,
      "redemptions" : 336250,
      "expirations" : 0
    }, {
      "firstDay" : {
        "year" : 2023,
        "month" : 1,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2023,
        "month" : 3,
        "day" : 31
      },
      "additions" : 18055239,
      "redemptions" : 813984,
      "expirations" : 0
    }, {
      "firstDay" : {
        "year" : 2023,
        "month" : 4,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2023,
        "month" : 5,
        "day" : 16
      },
      "additions" : 9264359,
      "redemptions" : 1532934,
      "expirations" : 0
    } ]
  },
  "test" : {
    "offers" : [ {
      "id" : 1,
      "name" : "restaurant_voucher_10",
      "label" : "Restaurant Voucher - $10 "
    }, {
      "id" : 4,
      "name" : "free_parking",
      "label" : "Free Parking"
    }, {
      "id" : 5,
      "name" : "miramar_high_season",
      "label" : "Miramar Hotel Stay (High Season)"
    }, {
      "id" : 7,
      "name" : "private_event_space_coast_lounge",
      "label" : "Private Event Space (Coast Lounge)"
    }, {
      "id" : 15,
      "name" : "movie_tickets_2",
      "label" : "Movie Tickets (2)"
    }, {
      "id" : 17,
      "name" : "santa_house_fast_pass",
      "label" : "Santa House Fast Pass 12/24"
    }, {
      "id" : 22,
      "name" : "restaurant_voucher_10_welcome",
      "label" : "Restaurant Voucher - $10 - Welcome"
    }, {
      "id" : 25,
      "name" : "restaurant_voucher_10_birthday",
      "label" : "Restaurant Voucher - $10 - Birthday"
    }, {
      "id" : 40,
      "name" : "restaurant_voucher_50_test_1_2",
      "label" : "Restaurant Voucher - $50"
    }, {
      "id" : 41,
      "name" : "private_event_space_great_room_test_1",
      "label" : "Private Event Space (Great Room)"
    }, {
      "id" : 42,
      "name" : "restaurant_voucher_75_test_1",
      "label" : "Restaurant Voucher - $75"
    }, {
      "id" : 44,
      "name" : "restaurant_voucher_100_test_1_2",
      "label" : "Restaurant Voucher - $100"
    }, {
      "id" : 49,
      "name" : "lny_amass_discount",
      "label" : "LNY Amass Discount"
    }, {
      "id" : 50,
      "name" : "unlimited_free_parking",
      "label" : "Unlimited Free Parking"
    }, {
      "id" : 51,
      "name" : "free_parking_1",
      "label" : "One Day Self-Parking"
    } ],
    "offerUsages" : [ {
      "firstDay" : {
        "year" : 2022,
        "month" : 1,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2022,
        "month" : 9,
        "day" : 30
      },
      "changesByOfferId" : {
        "15" : {
          "numNew" : 3,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "22" : {
          "numNew" : 2,
          "numCancelled" : 0,
          "numConsumed" : 1,
          "numExpired" : 0
        },
        "25" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 1
        },
        "5" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "1" : {
          "numNew" : 2,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "4" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 1
        }
      }
    }, {
      "firstDay" : {
        "year" : 2022,
        "month" : 10,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2022,
        "month" : 12,
        "day" : 31
      },
      "changesByOfferId" : {
        "22" : {
          "numNew" : 2,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "5" : {
          "numNew" : 0,
          "numCancelled" : 0,
          "numConsumed" : 1,
          "numExpired" : 0
        },
        "41" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "7" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "44" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "42" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "40" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "17" : {
          "numNew" : 2,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 2
        },
        "1" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "4" : {
          "numNew" : 3,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 3
        },
        "25" : {
          "numNew" : 3,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        }
      }
    }, {
      "firstDay" : {
        "year" : 2023,
        "month" : 1,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2023,
        "month" : 3,
        "day" : 31
      },
      "changesByOfferId" : {
        "4" : {
          "numNew" : 22,
          "numCancelled" : 3,
          "numConsumed" : 0,
          "numExpired" : 18
        },
        "25" : {
          "numNew" : 0,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 3
        },
        "49" : {
          "numNew" : 6,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "7" : {
          "numNew" : 0,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 1
        },
        "50" : {
          "numNew" : 1,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 1
        },
        "15" : {
          "numNew" : 8,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "22" : {
          "numNew" : 0,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 1
        },
        "1" : {
          "numNew" : 5,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        }
      }
    }, {
      "firstDay" : {
        "year" : 2023,
        "month" : 4,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2023,
        "month" : 5,
        "day" : 16
      },
      "changesByOfferId" : {
        "25" : {
          "numNew" : 15,
          "numCancelled" : 0,
          "numConsumed" : 1,
          "numExpired" : 1
        },
        "4" : {
          "numNew" : 35,
          "numCancelled" : 2,
          "numConsumed" : 0,
          "numExpired" : 33
        },
        "50" : {
          "numNew" : 13,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "51" : {
          "numNew" : 4,
          "numCancelled" : 1,
          "numConsumed" : 0,
          "numExpired" : 3
        },
        "15" : {
          "numNew" : 2,
          "numCancelled" : 7,
          "numConsumed" : 1,
          "numExpired" : 0
        },
        "41" : {
          "numNew" : 2,
          "numCancelled" : 3,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "1" : {
          "numNew" : 0,
          "numCancelled" : 5,
          "numConsumed" : 0,
          "numExpired" : 0
        },
        "22" : {
          "numNew" : 0,
          "numCancelled" : 0,
          "numConsumed" : 0,
          "numExpired" : 2
        }
      }
    } ],
    "coinUsages" : [ {
      "firstDay" : {
        "year" : 2022,
        "month" : 1,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2022,
        "month" : 9,
        "day" : 30
      },
      "additions" : 76298,
      "redemptions" : 20465,
      "expirations" : 0
    }, {
      "firstDay" : {
        "year" : 2022,
        "month" : 10,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2022,
        "month" : 12,
        "day" : 31
      },
      "additions" : 845319,
      "redemptions" : 744200,
      "expirations" : 0
    }, {
      "firstDay" : {
        "year" : 2023,
        "month" : 1,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2023,
        "month" : 3,
        "day" : 31
      },
      "additions" : 58150,
      "redemptions" : 13150,
      "expirations" : 0
    }, {
      "firstDay" : {
        "year" : 2023,
        "month" : 4,
        "day" : 1
      },
      "lastDay" : {
        "year" : 2023,
        "month" : 5,
        "day" : 16
      },
      "additions" : 269350,
      "redemptions" : 244375,
      "expirations" : 0
    } ]
  }
}

const ReportResultsArea = (props:{results:ReportResults})=>{
  const {results} = props

  return (
<>
            <Typography variant="h6">Coins</Typography>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell >Period</TableCell>
                    <TableCell align="right">New Coins</TableCell>
                    <TableCell align="right">Redeemed Coins</TableCell>
                    <TableCell align="right">Expired Coins</TableCell>
                    <TableCell align="right">Net Coins</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {results.coinUsages.map(pu => {
                    return (
                        <TableRow
                        key={format(pu.lastDay)}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell  > {format(pu.firstDay)} through {format(pu.lastDay)}</TableCell>
                            <TableCell align="right">{formatNumber(pu.additions)}</TableCell>
                            <TableCell align="right">{formatNumber(pu.redemptions)}</TableCell>
                            <TableCell align="right">{formatNumber(pu.expirations)}</TableCell>
                            <TableCell align="right">{formatNumber(pu.additions - pu.redemptions - pu.expirations)}</TableCell>
                        </TableRow>
                    )
                })}
                </TableBody>
            </Table>

            <Typography variant="h6">Offers</Typography>
            {results.offerUsages.map(pu => {
                return <div >
                    <Stack spacing={1}>
                        <Typography variant="h6">Period Ending {format(pu.lastDay)} (Since {format(pu.firstDay)})</Typography>

                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Offer ID</TableCell>
                                <TableCell align="right">New</TableCell>
                                <TableCell align="right">Consumed</TableCell>
                                <TableCell align="right">Expired</TableCell>
                                <TableCell align="right">Cancelled</TableCell>
                                <TableCell align="left">Current Label</TableCell>
                                <TableCell align="left">Current Internal Name</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {Object.keys(pu.changesByOfferId).map((offerId) => {
                                const offer = results.offers.find(o=>o.id === parseInt(offerId))
                                const data = pu.changesByOfferId[offerId]
                                
                                return (
                                <TableRow
                                key={offerId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" >{offerId}</TableCell>
                                <TableCell align="right">{data.numNew}</TableCell>
                                <TableCell align="right">{data.numConsumed}</TableCell>
                                <TableCell align="right">{data.numExpired}</TableCell>
                                <TableCell align="right">{data.numCancelled}</TableCell>
                                <TableCell align="left" scope="row">{offer?.label}</TableCell>
                                <TableCell align="left">{offer?.name}</TableCell>
                                </TableRow>
                            )
                            })}
                            </TableBody>
                        </Table>
                    </Stack>
                </div>
            })}
        </>
  )
}

const TabbedPanels = (props:{tabs:Record<string, ReactNode>})=>{

  const [value, setValue] = React.useState(0);

  const tabName = Object.keys(props.tabs)[value]

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log("Seleted", newValue)
    setValue(newValue);
  };


  function a11yProps(index: string) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (    <Stack sx={{ width: '100%' }} spacing={3}>
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
      {Object.keys(props.tabs).map((name)=>{
        return <Tab label={name} {...a11yProps(name)} />
      })}
    </Tabs>
  </Box>
  {
    props.tabs[tabName]
  }
</Stack>)
}

const formatNumber = (n:number) => n.toLocaleString("en-US")
const formatCoins = (n:number) => `${formatNumber(n)} coins`

const quarterBoundaries = ():string[]=>{
  const today = dayjs()
  let m = dayjs("2022-01-01", "YYYY-MM-DD")
  const results:string[] = []
  while(m.isBefore(today)){
    results.push(m.format("YYYY-MM-DD"))
    console.log(m)
    m = m.add(3, "month")
  }

  return results

}
// eslint-disable-next-line import/no-anonymous-default-export
export default (props:{})=>{
    const [isBusy, setBusy] = React.useState(false)
    const [error, setError] = React.useState<string>()
    const [dates, setDates] = React.useState<string>(quarterBoundaries().join("\n"))
    const [results, setResults] = React.useState<ReportResultsByTestStatus|undefined>()

    const doIt = ()=>{
        setBusy(true)
        setError(undefined)

        doExport(`/api/accounting/roll-forward?date-boundaries=${dates.split('\n').map(s=>s.trim()).filter(s=>s.length>0).join(",")}`).then(resultUrl => {
          get(resultUrl, (status, text) => {
              setBusy(false)
              if (status === 200) {
                setResults(JSON.parse(text))
              }else{
                setResults(undefined)
                setError(`Error ${status}: ${text}`)
              }
          })
        })
    }
    return (<Stack spacing={3} style={{margin:"20px"}}>
        <Typography variant="h3">Loyalty Accounting</Typography>
        
        <Typography variant="h4">Full Ledger</Typography>
        <Stack direction="row" spacing={3}>
          <ExportLink name="Offer Ledger" url="/api/accounting/offer-ledger.csv"/>
          <ExportLink name="Coin Ledger" url="/api/accounting/coin-ledger.csv"/>
        </Stack>

        <Typography variant="h4">Rollforward</Typography>
        <TextField
          id="filled-textarea"
          label="Date Boundaries"
          placeholder="Placeholder"
          multiline
          variant="filled"
          disabled={isBusy}
          value={dates}
          onChange={e=>setDates(e.target.value)}
        />
        {error && <Alert color="error">{error}</Alert>}
        {isBusy ? <CircularProgress/> : <>
            <Box>
                <Button disabled={isBusy} onClick={doIt} variant="contained">Calculate</Button>
            </Box>
        </>}

        {results && (<>
        
          <Typography variant="h5">Results</Typography>
          <TabbedPanels tabs={{
            "Real Accounts":<ReportResultsArea results = {results.normal}/>,
            "Test Accounts":<ReportResultsArea results = {results.test}/>}}/>
          </>)}
        
    </Stack>)

}
import React, {  } from 'react';
import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { postJson } from './http';
import LoginScreen, { UserInfo2 } from './LoginScreen';

const Template = (props:{children?: React.ReactNode})=>{


  return <Stack spacing={2} style={{ fontFamily: "Neutraface2Text-Book, Times", padding: "40px", textAlign: "center"  }}>
    <img src="/caruso-signature-header.jpg" alt="Caruso Signature header logo" />
    <Typography variant="h4" style={{ fontFamily: "Neutraface2Text-Book, Times", textAlign: "center" }} >
    Thank you for being a Caruso Signature Member!
    </Typography>
    {props.children}
  </Stack>
}

const SingleUseCodeExperience = (props:{user:UserInfo2}) => {
  const {user} = props
  const [result, setResult] = React.useState<{sucessful:boolean, message:string}>()

  return <Template>
    {result?.sucessful ? 
    <Typography  style={{ fontFamily: "Neutraface2Text-Book, Times", textAlign: "center" }}>
    Your reward has been applied to your Caruso Signature account: {result.message}
    </Typography> : <CodeEntryScreen
        user = {user}
        onResult = {setResult}
    />}
  </Template>
}

const CodeEntryScreen = (props:{user:UserInfo2, onResult:(result:{sucessful:boolean, message:string})=>void})=>{
  const {user, onResult} = props
  const [error, setError] = React.useState<string>()
  const [code, setCode] = React.useState<string>()
  const [working, setWorking] = React.useState(false)

  const consumeCode = ()=>{
    console.log("Consuming code", code)
    setWorking(true)
    
    postJson(
      "/api/code-redemptions",
      JSON.stringify({
        userId:user.cheetahId,
        code:code
      }),
      (status, body)=>{
        setWorking(false)
        if(status === 200){
          onResult({
            sucessful: true,
            message:body
          })
        }else{
          setError(body)
          onResult({
            sucessful: false,
            message:body
          })
        }
      }
    )
  }

  return <>
    <Typography  style={{ fontFamily: "Neutraface2Text-Book, Times", textAlign: "center" }}>
    Enter your code below to claim your reward
    </Typography>
    <Box>
    <TextField 
          required
          disabled = {working}
          value = {code}
          onChange = {e=>setCode(e.target.value)}
          label="Offer Code"></TextField>
    </Box>

    <Box>
      {working ? <CircularProgress/> : <Button variant='contained' disabled = {!code} onClick={consumeCode}>Submit</Button>}
    </Box>
    {error}
  </>
}


// eslint-disable-next-line import/no-anonymous-default-export
export default ()=> <LoginScreen 
  message = "Please sign into your Caruso Signature account to receive your special offer"
  loggedInContent={(user)=>
    <SingleUseCodeExperience 
        user = {user} />}/>;

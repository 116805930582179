import React from "react"
import { PropertyAddition } from "./api"
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"

const isEmpty = (s:string|undefined):boolean => !s || s.trim().length === 0

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { onChange: (update: PropertyAddition) => void, onClose: () => void }) => {
    const { onChange, onClose } = props

    const [isWorking, setWorking] = React.useState(false)
    const [name, setName] = React.useState<string>("")
    const [url, seturl] = React.useState<string>("")
    const [error, setError] = React.useState<string | undefined>(undefined)

    const doSave = () => {
        setWorking(true)
        setError(undefined)
        onChange({
            type: "property-addition",
            name: name,
            imageUrl: url,
        })
    }

    return <Dialog 
        open={true} 
        onClose={onClose}
        fullWidth
        maxWidth="sm">
        <DialogTitle>Add Property</DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
            <Stack spacing={2}>
                {error ? <Alert color="warning">{error}</Alert> : null}
                <TextField
                    required
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label="Name" />


                <TextField
                    required
                    value={url}
                    onChange={e => seturl(e.target.value)}
                    label="URL" />

            </Stack>
        </DialogContent>
        <DialogActions>
            {isWorking ? <CircularProgress /> : <>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" disabled={(isEmpty(name) || isEmpty(url))} onClick={doSave}>Save</Button>
            </>}
        </DialogActions>

    </Dialog>
}
import { AppBar, Button, CircularProgress, Dialog, DialogContent, IconButton,  Paper,  Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { CSSProperties, useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { get } from "./http";

import { TransitionProps } from "@mui/material/transitions";
import TruncatedJson from "./TruncatedJson";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getInteractionsForTrace = (traceId:string):Promise<any[]>=>{
    return new Promise((resolveSuccess, resolveFailed)=>{
        
        get(`/api/interactions?traceId=${traceId}`, (status, text) => {
            if (status === 200) {
                resolveSuccess(JSON.parse(text))
            } else {
                resolveFailed(`Server said ${status}: ${text}`)
            }
        })
    })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props:{traceId:string})=>{
    const {traceId} = props
    const [showDetails, setShowDetails] = useState(false)
    const [working, setWorking] = useState(false)
    const [interactions, setInteractions] = useState<any[]>()


    useEffect(()=>{
        if(showDetails){
            setWorking(true)
            getInteractionsForTrace(traceId)
                .then(setInteractions)
                .finally(()=>setWorking(false))
        }
    }, [showDetails])


    return (<>
        {!showDetails && <div><Button onClick={() => setShowDetails(true)}>Show Interactions </Button></div>}
        {showDetails && (
            <Dialog
                fullScreen
                open={true}
                onClose={() => setShowDetails(false)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setShowDetails(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            External Interactions Log
                        </Typography>

                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {working && <CircularProgress/>}
                                            <Typography>
                    <TableContainer component={Paper}>
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ID</TableCell>
                                    <TableCell align="left">request</TableCell>
                                    <TableCell align="left">result</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                interactions?.map(interaction => {
                                    const cellStyle:CSSProperties = {
                                        verticalAlign:"top"
                                    }
                                    const interactionId = interaction.id
                                        return (
                                            <TableRow 
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell style={cellStyle} align="left">{interactionId}</TableCell>
                                                <TableCell style={cellStyle} align="left">
                                                <Stack spacing={2}>
                                                            <Box><Typography>{interaction.request.method} {interaction.request.url}</Typography></Box>
                                                            <Box><Typography>When Sent: {interaction.whenSent}</Typography></Box>
                                                            <Box><Typography>Body: <a target="_blank" href={`/api/interactions/${interactionId}/request/body`} rel="noreferrer">Download</a></Typography></Box>
                                                            <InteractionHeadersView value={interaction.request.header}/>
                                                        </Stack>
                                                        {/* <TruncatedJson value={interaction.request}/> */}
                                                </TableCell>
                                                <TableCell style={cellStyle}  align="left">
                                                    
                                                    {interaction.result?.response && <Stack spacing={2}>
                                                            <Box><Typography>Status {interaction.result?.response.statusCode}</Typography></Box>
                                                            <Box><Typography>When Received: {interaction.result?.whenReceived}</Typography></Box>
                                                            <Box><Typography>Body: <a target="_blank" href={`/api/interactions/${interactionId}/response/body`} rel="noreferrer">Download</a></Typography></Box>
                                                            <InteractionHeadersView value={interaction.result.response.header}/>
                                                        </Stack>}
                                                    {interaction.result?.error && <TruncatedJson value={interaction.result?.error}></TruncatedJson>}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Typography>
                </DialogContent>
            </Dialog>)}
    </>
    )
}

const InteractionHeadersView = (props:{value:any}) => <>
<Typography>Header:</Typography>
<Table stickyHeader >
    <TableHead>
        {/* <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Value</TableCell>
        </TableRow> */}
    </TableHead>
    <TableBody>
        {props.value.map((v:any)=>{
            const {first, second} = v
            return <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell align="left"><Typography fontSize={"9pt"} fontFamily={"monospace"}>{first}</Typography></TableCell>
            <TableCell align="left"><Typography fontSize={"9pt"}  fontFamily={"monospace"}>{second}</Typography></TableCell>

            </TableRow>
        })}
    
    </TableBody>
</Table>

</>

const LongJson = (props:{children:any}) => <pre>{JSON.stringify(props.children, null, 4)}</pre>
import React, { ReactElement } from 'react';
import { Alert, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { get, postJson } from './http';

interface LoginValueThing {
    "integral": boolean | undefined,
    "valueType": "NUMBER" | "STRING",
    "chars": string | undefined,
    "string": string | undefined,
}

interface LoginStuff {
    "ExpiresIn": LoginValueThing,
    "IdToken": LoginValueThing,
    "RefreshToken": LoginValueThing,
    "TokenType": LoginValueThing,
    "MemberId": LoginValueThing
}

export interface LoginResponse {
    "success": boolean,
    "payload": LoginStuff,
    "status": 200,
    "message": string,
    "error": any | undefined | null,
    userVerified: boolean,
    "email": string | undefined | null
}

export interface UserInfo {
    userId: string,
    email: string
}

export interface UserInfo2 {
    id:string,
    email: string|undefined,
    cognitoId: string|undefined,
    cheetahId: string|undefined,
}

export interface UserInfoDto {
    id:string,
    email:string|undefined,
    cognitoId:string|undefined,
    cheetahId:string|undefined,
    isInsider:boolean
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { message: string, loggedInContent: (user: UserInfoDto) => ReactElement }) => {
    const { message, loggedInContent } = props
    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [error, setError] = React.useState<string>()
    const [login, setLogin] = React.useState<UserInfoDto>()
    const [isFetchingLogin, setFetchingLogin] = React.useState(true)
    const [isWorking, setWorking] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false)


    const getUserInfo = ()=>{
        get("/whoami", (status, text)=>{
            setFetchingLogin(false)
            if(status === 200){
                setLogin(JSON.parse(text))
                setWorking(false)
            }else if(status === 401){
                setLogin(undefined)
                setWorking(false)
            }else {
                setError(`Status ${status}: ${text}`)
            }
        })
    }

    const getRedirect = () => {
        setLogin(undefined)
        setWorking(true)
        setError(undefined)

        postJson(
            "/login-session",
            JSON.stringify({ "email": email, url:window.location.toString()}),
            (status, body) => {
                if(status === 200){
                   const url = JSON.parse(body)?.redirectUrl

                   if(url){
                    window.location = url
                   }else{

                    setWorking(false)
                    setShowPassword(true)
                   }

                }else{
                    setWorking(false)
                    setError("Couldn't log-in: " + body)
                }
            }
        )
    }

    const doLogin = () => {
        setLogin(undefined)
        setWorking(true)
        setError(undefined)

        postJson(
            "/login",
            JSON.stringify({ "email": email, "password": password }),
            (status, body) => {
                setWorking(false)
                if(status === 200){
                    getUserInfo()
                }else{
                    setError("Couldn't log-in: " + body)
                }
            }
        )
    }


    React.useEffect(getUserInfo, [])

    if(isFetchingLogin){
        return <CircularProgress/>
    }
    if (!isFetchingLogin && login) {
        console.log("User is", login)
        return loggedInContent(login)
    } else {

        return <Stack spacing={2} style={{ fontFamily: "Neutraface2Text-Book, Times", padding: "40px", textAlign: "center" }}>
            <img src="/caruso-signature-header.jpg" alt="Caruso Signature header logo" />
            {/* <Typography variant="h3" style={{ fontFamily: "Neutraface2Text-Book, Times", textAlign: "center" }} >
        Welcome Back
      </Typography> */}
            <Typography variant="h4" style={{ fontFamily: "Neutraface2Text-Book, Times", textAlign: "center", paddingBottom: "40px" }} >
                {message}
            </Typography>
            <Box>
                <TextField
                    required
                    disabled={isWorking || showPassword}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => {if (e.key === 'Enter' && email.length !== 0 && !showPassword) {getRedirect()}}}
                    type="email"
                    label="Email Address" />
            </Box>

            {showPassword && <Box>
                <TextField
                    required
                    disabled={isWorking}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => {if (e.key === 'Enter' && password.length !== 0) {doLogin()}}}
                    type="password"
                    label="Password"></TextField>
            </Box>}

            <Box>
                {isWorking ? <CircularProgress /> : <Button
                    disabled={isWorking || email.length === 0 || (showPassword && password.length === 0)}
                    variant='contained' onClick={showPassword ? doLogin : getRedirect}>Submit</Button>
                }

            </Box>
            <Box>
                {error && <Alert color="error">{error}</Alert>}
            </Box>
        </Stack>
    }
}
import React, { ReactNode, useState } from "react"
import { CarusoMap, CoinsTagConfig, DecoratedTagConfig, GenericUrlTagConfig, InPersonFulfillmentTagConfig, OfferOverview, OfferTagConfig, TagConfig, TagConfigList, TagType } from "./api"
import { Alert, Box, CircularProgress, Divider, Fab, Grid, IconButton, MenuItem, Stack, Typography } from "@mui/material"
import SimpleMarkdown from "./SimpleMarkdown"
import { TagStatusFilter, getMap, getMapPromise, getTags, getTagsPromise, listOffers } from "./api-actions"
import { Link, useNavigate, useParams } from "react-router-dom"
import { UserInfoDto } from "./LoginScreen"
import AppTagScanPagePreview from "./AppTagScanPagePreview"
import { Link as MuiLink } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import TagPickupsDialog from "./TagPickupsDialog"
import LabeledSelect from "./LabeledSelect"
import ReferrerLabel from "./ReferrerLabel";


const publishedState = "published"

export const SingleTagAdminPage = (props: { user: UserInfoDto }) => {
    const { tagId } = useParams()
    const { user } = props
    const [map, setMap] = React.useState<CarusoMap>()
    const [tags, setTags] = React.useState<TagConfigList>()
    const [editing, setEditing] = useState(false)
    const [busy, setBusy] = useState(false)

    const [offers, setOffers] = useState<OfferOverview[]>()

    const refresh = () => {
        setBusy(true)
        Promise.all([
            getMapPromise().then(setMap),
            listOffers().then(setOffers),
            getTagsPromise().then(setTags),
        ]).finally(() => setBusy(false))
    }
    React.useEffect(refresh, [])

    const decorated = tags?.tags.find(it => it.tag.tagId == tagId)
    const internalName = decorated?.tag?.internalName
    
    return (<>
        {(editing && decorated && map && offers) && <TagPickupsDialog
            existing={decorated.tag}
            map={map}
            offers={offers}
            onClose={() => setEditing(false)}
            onComplete={(decoratedTag) => {
                setEditing(false)
                refresh()
            }}
        />}
        <Stack spacing={3} style={{ margin: "40px" }}>
            <Typography variant="h2">Tag {tagId?.substring(0, 5)} {internalName && ` - ${internalName}`}<IconButton onClick={() => setEditing(true)}><EditIcon /></IconButton></Typography>
            {(!decorated || busy) && <CircularProgress />}
            {decorated && <TagAdminView decorated={decorated} map={map} user={user} />}
        </Stack>
    </>)
}


const TagAdminView = (props: { decorated: DecoratedTagConfig, map: CarusoMap | undefined, user: UserInfoDto }) => {
    const { decorated, map, user } = props

    const { tag, offer, url } = decorated
    const property = map?.properties.find(p => p.id == tag.propertyId)
    const coinsTag = tag.type === "coins" ? tag as CoinsTagConfig : undefined
    const automatedOfferTag = (tag.type === "offer") ? (tag as OfferTagConfig) : undefined
    const inPersonTag = (tag.type === "in-person-fulfillment") ? (tag as InPersonFulfillmentTagConfig) : undefined

    const offerProvidingTag = automatedOfferTag || inPersonTag

    const validUntil = (automatedOfferTag?.validUntil ?? inPersonTag?.validUntil ?? coinsTag?.validUntil)
    const singleUse = (automatedOfferTag?.singleUse ?? inPersonTag?.singleUse ?? coinsTag?.singleUse)
    const expiration = validUntil ? new Date(validUntil) : undefined
    const tagId = decorated.tag.tagId

    const [previewUserEmail, setPreviewUserEmail] = useState("spenrose+test99000@caruso.com")

    const typeDescriptions: Record<TagType, string | undefined> = {
        "offer": "Pickup an Offer",
        "coins": "Pickup Coins",
        "dummytag": "Dummy Tag for Testing",
        "in-person-fulfillment": "Scan for In-Person Fulfillment",
        "signup": "Signup Tag",
        "generic-url": "Generic URL Tag",
    }
    

    const outsiderImage = "https://carusosignature.com/assets/images/caruso-blue-logo.svg"

    const linkToCheetahOfferPage = (offerId: number) => `https://cust1240.cheetahedp.com/console/offers/${offerId}`


    const makeDownloadHandler = (name: string, url: string): React.MouseEventHandler<HTMLAnchorElement> => {
        return (e) => {
            e.preventDefault()
            const link = document.createElement("a");
            link.download = name;
            link.href = url;
            link.target = "_blank"
            link.click();
        }
    };

    const genericUrlTag = (tag.type === "generic-url") ? tag as GenericUrlTagConfig : undefined

    const mutationKey = JSON.stringify(decorated.tag)

    const destinationUrlExperienceNoApp = genericUrlTag && <>The user is taken to <MuiLink href={genericUrlTag.url} target="_blank">{genericUrlTag.url}</MuiLink></>
    const destinationUrlExperienceNoAppAlert = destinationUrlExperienceNoApp && <Box style={{margin:"20px"}}><Alert color="info">{destinationUrlExperienceNoApp}</Alert></Box>

    let destinationUrlExperienceAppInstall:ReactNode|undefined

    if(!genericUrlTag){
        destinationUrlExperienceAppInstall = undefined
    }else if(genericUrlTag.url.startsWith("https://carusosignature.com") || genericUrlTag.url.startsWith("http://carusosignature.com")){
        destinationUrlExperienceAppInstall = "The user is taken to the app"
    } else if(genericUrlTag.useMemberTracking){
        destinationUrlExperienceAppInstall = (<>
            The user is 
            <ul>
                <li>taken to the app (for tracking purposes) </li>
                <li>then:
                    <ul>
                        <li>if using app version 26.0 or later: automatically sent back to the browser to continue on to <MuiLink href={genericUrlTag.url} target="_blank">{genericUrlTag.url}</MuiLink></li>
                        <li>if using app version before 26.0: left in the app</li>
                    </ul>
                </li>
            </ul>
        </>)
    }else{
        destinationUrlExperienceAppInstall = destinationUrlExperienceNoApp
    }

    const destinationUrlExperienceAppInstallAlert = destinationUrlExperienceAppInstall && (
        <Box style={{margin:"20px"}}>
            <Alert color="info">
                {destinationUrlExperienceAppInstall}
            </Alert>
        </Box>
    )

    const internalName = decorated.tag.internalName

    return (
        <Grid container spacing={2}>
            <Grid xs={12} md={6} lg={4}>
                <Typography variant="h5">Overview</Typography>
                {/* <Typography variant="h5">{internalName ? `"${internalName}"` : "Overview"}</Typography> */}
                {(decorated.offer && decorated.offer?.status !== publishedState && <Alert color="error"><Link to={linkToCheetahOfferPage(decorated.offer.id)}>Offer {decorated.offer.id} is in {decorated.offer.status} status.  To activate this Tag, set it to "{publishedState}".</Link></Alert>)}
                <Stack spacing={2} style={{ padding: "20px" }}>
                    <Box style={{ textAlign: "center", }}>
                        <img src={`/api/tag-images/${tag.tagId}`} style={{ width: "100%", maxWidth: "500px" }} alt="Tag QR Code" />
                    </Box>
                    <Stack direction={'row'} spacing={2}>
                        <Typography>Downloads:</Typography>
                        <MuiLink style={{ cursor: "pointer" }} onClick={makeDownloadHandler(`tag-${tagId}-qr-code-standard.svg`, `/api/tag-images/${tag.tagId}?style=standard`)}>Standard QR</MuiLink>
                        <MuiLink style={{ cursor: "pointer" }} onClick={makeDownloadHandler(`tag-${tagId}-qr-code-rounded.svg`, `/api/tag-images/${tag.tagId}?style=rounded`)}>Rounded QR</MuiLink>
                    </Stack>
                    
                    <div>Type: {typeDescriptions[tag.type] ?? tag.type}</div>
                    <div>Tag Url: <a target="_blank" href={url} rel="noreferrer">{url}</a></div>
                    <div>Referrer: {tag.referrer ? <ReferrerLabel value={tag.referrer}/> : "none"}</div>
                    <div>Property: {property?.name}</div>
                    {coinsTag && <div>Coins: {coinsTag.numCoins}</div>}
                    {offerProvidingTag?.offerId && <div>Offer ID: <a target="_blank" href={linkToCheetahOfferPage(offerProvidingTag.offerId)} rel="noreferrer">#{offerProvidingTag?.offerId} ("{offer?.label}")</a></div>}
                    <div>Single Use: {singleUse ? "yes" : "no"}</div>
                    <div>Expires: {expiration ? `${expiration.toLocaleDateString()} ${expiration.toLocaleTimeString()}` : "never"}</div>

                </Stack>
                <Box>

                </Box>
            </Grid>
            <Grid xs={12} md={6} lg={4}>
                <Typography variant="h5">Experience without App Installed</Typography>
                {genericUrlTag ? destinationUrlExperienceNoAppAlert : <>
                <Box style={{ padding: "20px", margin: "20px", border: "1px solid grey" }}>
                    <Box>
                        <Box style={{ margin: "50px" }}><img style={{ maxWidth: "100%" }} src={outsiderImage} alt="Outsider Header Img" /></Box>
                        <Typography>
                            <SimpleMarkdown>{decorated.outsiderView.description}</SimpleMarkdown>
                        </Typography>
                    </Box>
                </Box>
                <Stack spacing={2} style={{ padding: "10px" }}>
                    <Alert color="info">This is just a rough approximation, fonts and layout may differ in production, and between devices/browsers.
                        Additionally, download links may be added.</Alert>
                    <Alert color="info">Click <a target="_blank" href={url} rel="noreferrer">here</a> on desktop (or a mobile device without the app installed) to see the live version.</Alert>
                </Stack>
                </>}
            </Grid>

            <Grid xs={12} md={6} lg={4}>
                <Typography variant="h5">App Experience</Typography>
                {genericUrlTag ? destinationUrlExperienceAppInstallAlert:
                <Stack spacing={3}>
                    <Box style={{ padding: "20px", margin: "20px", border: "1px solid grey" }}>
                        {user?.email && <Box key={mutationKey}><AppTagScanPagePreview tagId={tagId} email={previewUserEmail} /></Box>}
                    </Box>

                    <Stack spacing={2} style={{ padding: "10px" }}>
                        <Alert color="info">Using {previewUserEmail} for preview</Alert>
                    </Stack>
                </Stack>
                }
            </Grid>
        </Grid>)
}

interface TagEditingSession {
    existing: TagConfig | undefined
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { user: UserInfoDto }) => {
    const { user } = props
    const [map, setMap] = React.useState<CarusoMap>()
    const [tags, setTags] = React.useState<TagConfigList>()
    const [editing, setEditing] = useState<TagEditingSession | undefined>()
    const [statusFilter, setStatusFilter] = React.useState<TagStatusFilter>("active")
    const [isFetching, setFetching] = useState(false)
    const navigate = useNavigate()

    const [offers, setOffers] = useState<OfferOverview[]>()

    React.useEffect(() => {
        setFetching(true)
        getTags(statusFilter, (tags) => {
            setTags(tags)
            setFetching(false)
        })
    }, [statusFilter])


    React.useEffect(() => {
        getMap(setMap)
        listOffers().then(setOffers)
    }, [])


    console.log("Tags are", tags)

    const isReady = map && tags && !isFetching
    return (<>
        {(editing && map && offers) && <TagPickupsDialog
            existing={editing?.existing}
            map={map}
            offers={offers}
            onClose={() => {
                setEditing(undefined)
            }}
            onComplete={(tagId) => {
                navigate(`/admin/tags/${tagId}`)
            }}
        />}

        <Stack spacing={4} style={{ margin: "20px" }}>
            <Stack direction="row-reverse">
                <LabeledSelect fullWidth={false} label="Status" labelId="status-picker" value={statusFilter} onChange={(e) => { setStatusFilter(e.target.value as any) }}>
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="expired">Expired</MenuItem>
                </LabeledSelect>
            </Stack>
            {!isReady && <CircularProgress />}
            {isReady && <>
                <Fab color="primary" style={{ position: "fixed", bottom: "30px", right: "30px" }} onClick={() => setEditing({ existing: undefined })}><AddIcon /></Fab>
                
                {tags?.tags.map(decorated => {
                    const { tag, } = decorated
                    const internalName = tag?.internalName
                    const tagId = decorated.tag.tagId
                    return (<>
                        <Typography variant="h4"><Link to={`/admin/tags/${tagId}`}>Tag {tagId.substring(0, 5)}</Link> {internalName && <>- <Typography variant="h4" style={{display:"inline"}}>{internalName}</Typography></>} <IconButton onClick={() => setEditing({ existing: tag })}><EditIcon /></IconButton> </Typography> 
                        <TagAdminView decorated={decorated} map={map} user={user} />
                        <Divider />
                    </>
                    )
                })}
            </>}
        </Stack>

    </>)
}
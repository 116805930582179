import { Tab, Tabs } from "@mui/material";
import React from "react";
import {  useNavigate, useParams } from "react-router-dom";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: {}) => {
    const tabs: Record<string, string> = {
        "Executive Summary": "qj5Qp",
        "Signature Dashboard": "oYKZX"
    }
    const tabNames = Object.keys(tabs)

    const { dashboardId } = useParams()
    const selectedTab = tabNames.indexOf(dashboardId ?? "")
    const embedId = tabs[dashboardId as string]
    const nav = useNavigate()
    const setSelectedTab = (n: number) => {
        nav(`/admin/dashboards/${tabNames[n]}`)
    }

    console.log("Tab index is ", selectedTab)

    const backend = "" //"http://localhost:8080"
    const headerHeight = 75

    React.useEffect(() => {
        if (dashboardId === "default") {
            nav(`/admin/dashboards/${tabNames[0]}`)
        }
    }, [])

    return (
        <>
            <html>
                <body style={{ margin: "0px", padding: "0px" }}>
                    <div style={{ height: `${headerHeight}px` }}>
                        <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)} aria-label="basic tabs example">
                            {tabNames.map(tabName => {
                                return <Tab label={tabName} />
                            })}
                        </Tabs>

                    </div>
                    <iframe style={{ border: "0px", width: "calc(100vw - 10px)", height: `calc(100vh - ${headerHeight + 30}px)` }} src={`${backend}/api/domo/embeds/${embedId}`} title="Domo Dashboard" />
                </body>
            </html>
        </>
    )
}

import { CarusoMap, CarusoMapProperty, CarusoTenant } from "./api";


export interface TenantAndProperty{
    tenant:CarusoTenant,
    property:CarusoMapProperty,
}

export const flattenTenants = (map:CarusoMap):TenantAndProperty[] | undefined => map.properties.flatMap(p=>p.tenants.map(t=>{
    return {
        tenant:t,
        property:p,
    }
}))

export const getTenant = (tenantId:String, map:CarusoMap):TenantAndProperty|undefined =>  flattenTenants(map)?.find(r=>r.tenant.id === tenantId)

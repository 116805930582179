import { AppBar, Button, Dialog, DialogContent, IconButton,  Slide, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


// eslint-disable-next-line import/no-anonymous-default-export
export default (props:{value:any})=>{
    const {value} = props
    const [showDetails, setShowDetails] = useState(false)
    const lines = JSON.stringify(value, null, 4).split("\n")
    const toShow = lines.slice(0, 10)
    return (<>
        <pre style={{overflow:"clip"}}>{toShow.join("\n")}</pre>
        {toShow.length !== lines.length && <div>Truncated <Button onClick={() => setShowDetails(true)}>Show More</Button></div>}
        {showDetails && (
            <Dialog
                fullScreen
                open={true}
                onClose={() => setShowDetails(false)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setShowDetails(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            JSON Data
                        </Typography>

                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Box style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(value, null, 4)}</Box>
                </DialogContent>
            </Dialog>)}
    </>
    )
}
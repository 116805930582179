import React from "react"
import { del, get, postJson } from "./http"
import { Alert, Button, Checkbox, CircularProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material"

interface TestMemberInfo {
    id: number,
    email: string,
    memberId: string,
    memberSince: number | undefined,
    firstName: string,
    lastName: string,
    lastActiveAt: number | undefined,
}

interface NewTestMemberRequest {
    email:string
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: {}) => {

    const [isBusy, setBusy] = React.useState(true)
    const [error, setError] = React.useState<string>()
    const [results, setResults] = React.useState<TestMemberInfo[] | undefined>()
    const [selections, setSelections] = React.useState<string[]>([])
    const [email, setEmail] = React.useState("")

    const fetchUsers = () => {
        setBusy(true)
        setError(undefined)
        get(`/api/test-users`, (status, text) => {
            setBusy(false)
            if (status === 200) {
                setResults(JSON.parse(text))
                setError(undefined)
            } else {
                setResults(undefined)
                setError(`Error ${status}: ${text}`)
            }
        })
    }

    const doAdd = ()=>{
        console.log("Adding " + email)

        const request:NewTestMemberRequest = {
            email:email
        }

        setBusy(true)
        setError(undefined)
        postJson(`/api/test-users`, JSON.stringify(request), (status, text) => {
            setBusy(false)
            if (status === 200) {
                fetchUsers()
            } else {
                setError(`Error ${status}: ${text}`)
            }
        })
    }

    const deleteSelected = ()=>{
        console.log("Deleting " + selections)

        setBusy(true)
        setError(undefined)

        del(`/api/test-users/${selections.join(",")}`, (status, text) => {
            setBusy(false)
            if (status === 200) {
                fetchUsers()
                setSelections([])
            } else {
                setError(`Error ${status}: ${text}`)
            }
        })
    }

    const select = (selected:boolean, member:TestMemberInfo)=>{
        if(selected){
            setSelections(selections.concat([member.memberId]))
        }else{
            setSelections(selections.filter(s => s !== member.memberId))
        }
    }

    const formatUnixTimestamp = (n: number) => new Date(n).toDateString()

    console.log("Selections are ", selections)

    React.useEffect(fetchUsers, [])

        return (<Stack spacing={3}>
            <Typography variant="h5">Test Accounts</Typography>
            <Stack direction="row" spacing={3} style={{ margin: "20px" }}>
                <TextField id="standard-basic" label="email" variant="outlined" onChange={e=>setEmail(e.target.value)}/><Button variant="contained" onClick={doAdd}>Add</Button>
                {isBusy && <CircularProgress />}
            </Stack>
            <Stack direction="row-reverse" style={{marginRight:"20px"}}><Button disabled={selections.length === 0} onClick={deleteSelected}>Delete</Button></Stack>
            {error && <Alert color="error">{error}</Alert>}
            {results && 
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>System ID</TableCell>
                        <TableCell align="right">Member ID</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">First</TableCell>
                        <TableCell align="left">Last</TableCell>
                        <TableCell align="right">Member Since</TableCell>
                        <TableCell align="left">Last Active</TableCell>
                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results.map((result) => {
                        const isSelected = selections.indexOf(result.memberId) > -1
                        return (
                            <TableRow
                                key={result.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell><Checkbox checked={isSelected} onChange={e=>select(e.target.checked, result)}/></TableCell>
                                <TableCell component="th" >{result.id}</TableCell>
                                <TableCell align="right">{result.memberId}</TableCell>
                                <TableCell align="left">{result.email}</TableCell>
                                <TableCell align="left">{result.firstName}</TableCell>
                                <TableCell align="left">{result.lastName}</TableCell>
                                <TableCell align="right">{result.memberSince && formatUnixTimestamp(result.memberSince)}</TableCell>
                                <TableCell align="right">{result.lastActiveAt && formatUnixTimestamp(result.lastActiveAt)}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            }
        </Stack>)
}
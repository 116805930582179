import React, { ReactNode, useState } from "react"
import { ActivityListItem, TransactionDetails } from "./api"
import { AppBar, Box,Dialog, DialogContent, Divider, IconButton, Link, Slide, Stack, Toolbar, Typography } from "@mui/material"

import CloseIcon from '@mui/icons-material/Close';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { TransitionProps } from '@mui/material/transitions';
import dayjs from "dayjs"
import { formattedColorAmount } from "./Util"
import ActivityLink from "./ActivityLink"
import _ from "underscore"

interface MetricAmount {
    amount: number
    metric: string
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const toDetails = (sl_items: any[]): Record<string, any> => {
    let details: Record<string, any> = {}
    sl_items.forEach(i => {
        details[i.sl_name] = i.sl_description
    })
    return details
}

const getTransactionDetails =  (transactionId:string):Promise<TransactionDetails> => {
    return fetch(`/api/fidel/transactions/${transactionId}`).then(async x => {
        const text = await x.text()
        return JSON.parse(text) as TransactionDetails
    })
}


const TransactionDetailsLink = (props:{name?:string, txId:string})=>{
    const {name, txId} = props
    const [showDetail, setShowDetails] = useState<TransactionDetails>()

    return (<>
        {showDetail && (
            <Dialog
                fullScreen
                open={true}
                onClose={() => setShowDetails(undefined)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setShowDetails(undefined)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Transaction Raw Data
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Box style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(showDetail, null, 4)}</Box>
                </DialogContent>
            </Dialog>)}
        <Link 
            style={{cursor:"pointer"}} 
            onClick={()=>{
                console.log("Need to show tx ", txId)
                getTransactionDetails(txId).then(setShowDetails)
            }}>
            {name || <>Transaction {txId.substring(0, 5)}</>}
        </Link>
    </>)
}

const detailFunctionsByType: Record<string, (a: ActivityListItem) => ReactNode> = {
    "sl_member_attribute": (a) => (<>
        {a.details.sl_items?.map(item => {
            return <Box>Attribute Changed: "{item.sl_attribute}"
                <ul>
                    <li>From: {item.sl_prev_value}</li>
                    <li>To: {item.sl_attribute_value}</li>
                </ul>
            </Box>
        })}
    </>),
    "send_referral": a => (<>
        {a.details.sl_label}
    </>),
    "sl_adjust_earn": a => (<>
        {a.details.sl_label}
    </>),
    "sl_coupon": (a) => (<>
        {a.details.sl_label}
    </>),
    "sl_offer": a => (<>
        {a.details.sl_label}
    </>),
    "sl_offer_cancel": a => (<>
        {a.details.sl_label}
    </>),
    "sl_reward": a => (<>
        {a.details.sl_label}
    </>),
    "sl_purchase_return": a=> {
        const [brand, cardScheme, cardNums] = (a.details.sl_comment ?? "").split("|")
        return (<>
            <Stack>
                {a.details.sl_transaction_id ? <TransactionDetailsLink txId={a.details.sl_transaction_id}/> : "missing transaction id"}
                <Box>{brand}</Box>
                <Box>{cardScheme} {cardNums}</Box>
            </Stack>
        </>)
    },
    "purchase_auth": a => {
        const [brand, cardScheme, cardNums] = (a.details.sl_comment ?? "").split("|")
        return (<>
            <Stack>
                <TransactionDetailsLink txId={a.details.sl_integration_id ?? "??"}/>
                <Box>{brand}</Box>
                <Box>{cardScheme} {cardNums}</Box>
            </Stack>
        </>)
    },
    "sl_survey": a => {

        const itemsByQuestion: Record<string, any[]> = {}

        a.details.sl_items?.forEach(i => {
            const questionId = i.sl_question_id
            const items: any[] = itemsByQuestion[questionId] ?? []
            itemsByQuestion[questionId] = items.concat([i])
        });

        return Object.keys(itemsByQuestion).map(questionId => {
            const items = itemsByQuestion[questionId]
            return (<div>
                <div>{items[0].sl_question}</div>
                <ul>
                    {items.map(i => {
                        return <li>{i.sl_question_response}</li>
                    })}
                </ul>
            </div>)
        })
    },
    "sl_purchase": a => {
        const d = toDetails(a.details.sl_items ?? [])
        return (<>
            <TransactionDetailsLink txId={a.details.sl_transaction_id ?? "??"}/>

            <Stack spacing={2}>
                <Box>
                    <Box>{d["BrandName"]}</Box>
                    <Box>{d["address"]}</Box>
                    <Box>{d["city"]}</Box>
                    <Box>{d["state"]}</Box>
                    <Box>{d["postalCode"]}</Box>
                </Box>
            </Stack>
        </>)
    }
}


export const detailFn = (a: ActivityListItem): ReactNode => {
    const fn = detailFunctionsByType[a.details.sl_type]

    if (fn) {
        return fn(a)
    } else {
        return <></>
    }
}

export const ActivityList = (props:{activitiesToShow:ActivityListItem[], setFocus: (a: ActivityListItem) => void })=>{
    const {activitiesToShow, setFocus} = props

    return (<>

        <Paper>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>When</TableCell>
                        <TableCell align="left">What</TableCell>
                        <TableCell align="left">Details</TableCell>
                        <TableCell align="left">Purchase Amount</TableCell>
                        <TableCell align="right">Balances Change</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {activitiesToShow.map((activity, idx) => {
                        const d = toDetails(activity.details.sl_items ?? [])
                        const when = dayjs(activity.activity_ts)

                        const ma = parseFloat(activity.metric_amount)


                        let metricChange: MetricAmount | undefined = undefined

                        if (ma) {
                            metricChange = { amount: ma, metric: activity.metric_label }
                        } else if (activity.details.sl_value && activity.details.sl_metric) {
                            const modifier = (activity.details.sl_type === "sl_reward") ? -1 : 1
                            metricChange = { amount: activity.details.sl_value * modifier, metric: activity.details.sl_metric }
                        }

                        return <TableRow
                            key={idx}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <Stack spacing={2}>
                                    <Box>{when.from(dayjs())}</Box>
                                    <Box>{when.format("YYYY-MM-DD")}</Box>
                                    <Box>{when.format("h:mm a")}</Box>
                                    <Box># {activity.details?.sl_id}</Box>
                                </Stack>
                            </TableCell>
                            <TableCell align="left">
                                <ActivityLink
                                    activity={activity}
                                    onClick={() => setFocus(activity)} />
                            </TableCell>
                            <TableCell align="left">{detailFn && detailFn(activity)}</TableCell>
                            <TableCell>
                                {(activity.details.sl_type === "sl_purchase") && <Box>
                                    <Box>Total: {formattedColorAmount(activity.details.sl_total ?? 0, activity.details.sl_transaction_currency ?? "??")}</Box>
                                    <Box>{d["scheme"]} {d["lastNumbers"]}</Box>
                                </Box>}
                            </TableCell>
                            <TableCell align="right">{metricChange && <Stack>
                                {Object.keys(activity.details.sl_balance).map(key => {
                                    const amount = activity.details.sl_balance[key][0]
                                    if (amount) {
                                        return <Box>{amount} {key} </Box>
                                    }
                                })}
                                <Divider />
                                <Box>{metricChange.amount > 0 && '+'} {metricChange.amount} {metricChange.metric}  </Box>
                            </Stack>}</TableCell>
                            {/* <TableCell align="left"><Box style={{whiteSpace:"pre-wrap"}}>{JSON.stringify(activity.details, null, 4)}</Box></TableCell> */}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </Paper></>)
}


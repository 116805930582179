/* eslint-disable jsx-a11y/anchor-is-valid */
import { UserInfoDto } from "./LoginScreen";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Dialog, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";

import PersonIcon from '@mui/icons-material/Person';
import { blue } from "@mui/material/colors";

function onlyUnique<T>(value: T, index: number, array: T[]) {
    return array.indexOf(value) === index;
}

function SimpleDialog(props: { user: UserInfoDto, onClose: () => void }) {
    const { user, onClose } = props;


    const navigate = useNavigate()

    const infos = [user.email, user.cheetahId, user.cognitoId, user.isInsider ? "Insider" : undefined].filter(next => next && next !== user.id)

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>
                <Stack direction="row" spacing={2}>
                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                        <PersonIcon />
                    </Avatar> <Box>{user.id}</Box>
                </Stack>
            </DialogTitle>

            {infos.length > 0 && <>
                <Stack spacing={2} style={{ margin: "20px" }}>
                    {infos.map(info => <Typography>{info}</Typography>)}
                </Stack>
                <Divider />
            </>}
            <List sx={{ pt: 0 }}>

                <ListItem disableGutters>
                    <ListItemButton onClick={() => (window as Window).location = "/logout"} key={"logout"}>

                        <ListItemText primary={"Logout"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Dialog>
    );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { user: UserInfoDto, children?: ReactNode | string }) => {
    const { user, children } = props
    const [showDialog, setShowDialog] = React.useState(false)
    const navigate = useNavigate()
    return (<Stack>
        <Box className="header" style={{ background: "rgb(30 30 30)", color: 'white', margin: "0px" }}>
            <Box style={{ margin: "10px", fontWeight: "bold", float: "left" }}>
                <a style={{ color: "white" }} onClick={() => navigate("/admin")}>Caruso Platform</a>
            </Box>
            <Box style={{ margin: "10px", fontWeight: "bold", textAlign: "right" }} >
                <a style={{ color: "white" }} onClick={() => setShowDialog(true)}>{user.id}</a>
                {showDialog && <SimpleDialog user={user} onClose={() => setShowDialog(false)} />}
            </Box>
        </Box>
        {children}
    </Stack>)

}
import { Alert, Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

interface EmailSignupRequest {
    first_name: string,
    last_name: string,
    email: string,
    updatedEmail: string | undefined,
    birthdate: string,
    zipCode: string,
    password: string | undefined,
    mobile_phone: string | undefined,
    referralCode: string | undefined,
    agree_caruso_tc: string | undefined,
    app_device_id: string | undefined,
}

const tryOrUndefined = <T,>(fn: () => T): T | undefined => {
    try {
        return fn()
    } catch {
        return undefined
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const { referrerId } = useParams()
    const outsiderImage = "https://carusosignature.com/assets/images/caruso-blue-logo.svg"
    const defaultEmail = `spenrose+test${new Date().getTime()}@caruso.com`
    const [email, setEmail] = useState(defaultEmail)
    const [emailConfirmation, setEmailConfirmation] = useState(defaultEmail)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [error, setError] = useState<string>()
    const [busy, setBusy] = useState(false)
    const navigate = useNavigate()

    const doSignup = () => {

        if (email.length === 0) {
            setError("Please enter your email to accept this invitation")
        // } else if (password.length === 0) {
        //     setError("Please choose a password to accept this invitation")
        // } else if (password !== confirmPassword) {
        //     setError("Passwords don't match")
        } else {
            setError(undefined)
            setBusy(true)

            const enrollUrl = `https://carusosignature.com/?signup-email=${encodeURIComponent(email)}&signup-referrer=${encodeURIComponent(referrerId as string)}#/user/enroll`;
            // const r: EmailSignupRequest = {
            //     first_name: "",
            //     last_name: "",
            //     email,
            //     updatedEmail: undefined,
            //     birthdate: "1900-01-01",
            //     zipCode: "0001",
            //     password: password,
            //     mobile_phone: undefined,
            //     referralCode: undefined,
            //     agree_caruso_tc: undefined,
            //     app_device_id: undefined,
            // }
            // postJson(
            //     "/api/members",
            //     JSON.stringify(r),
            //     (status, body, xhr) => {
            //         if (status == 200) {
            //             (window as any).location = enrollUrl
            //         } else {
            //             const parsed: any = tryOrUndefined(() => JSON.parse(body))
            //             setError(parsed?.message || body)
            //             setBusy(false)
            //         }
            //     }
            // )
            (window as any).location = enrollUrl;
        }
    }

    return (<>
        {/* {referrerId} */}
        <Box style={{ margin: "40px" }}>

            <Typography>
                <Stack spacing={4}>
                    <Box style={{ textAlign: "center" }}><img style={{ maxWidth: "500px", width: "100%" }} src={outsiderImage} alt="Caruso Signature logo" /></Box>
                    <Box style={{ textAlign: "center" }}>
                        <Box style={{ maxWidth: "500px", width: "100%", display: "inline-block" }}>
                            <Stack spacing={2}>
                                <Typography variant="h4" style={{ fontStyle: "italic" }}>Accept Your Special Invitation to the World of Caruso</Typography>

                                {/* <Divider>Create Your Account to Confirm</Divider> */}
                                <TextField label="email" value={email} onChange={e => setEmail(e.target.value)}></TextField>
                                <TextField label="confirm email" value={emailConfirmation} onChange={e => setEmailConfirmation(e.target.value)}></TextField>
                                {/* <TextField label="choose a password" type="password" value={password} onChange={e => setPassword(e.target.value)}></TextField>
                                <TextField label="choose a password (confirm)" type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}></TextField> */}
                                {!busy && <>
                                    <Button variant="contained" onClick={doSignup}>Continue</Button>
                                </>}
                                {busy && <CircularProgress />}
                                {error && <Alert color="error">{error}</Alert>}
                            </Stack>
                        </Box>
                    </Box>
                </Stack>
            </Typography>
        </Box>
    </>)
}

import { useEffect, useState } from 'react';
import AppTagScanPage, { TagAndMember } from './AppTagScanPage';

const fetchProfileByEmail = (email:string) => fetch(`/api/profilesByEmail/${encodeURIComponent(email)}`)

// eslint-disable-next-line import/no-anonymous-default-export
export default (props:{tagId: string, email:string}) => {
    const {tagId, email} = props

    const appRoot = "https://carusosignature.com"
    const makeTagUrl = (args:TagAndMember) =>  `/api/members/${args.memberId}/tags/${args.tagId}`
    
    const [userFetch, setUserFetch] = useState(fetchProfileByEmail(email))

    return <AppTagScanPage 
        tagId={tagId}
        submitTagAccept={t=> {
            return Promise.reject("This is just a preview ... you can't actually accept this")
        }}
        fetchProfile={()=>userFetch}
        fetchTagConfig={t=> fetch(makeTagUrl(t))}
        navigateAwayLeavingNoTraceInHistory={url=>{console.log("Fake navigation to ", url)}}
    />
}

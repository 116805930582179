import { useEffect, useState } from 'react';
import { postJson } from "./http";
import {
    usePlaidLink,
    PlaidLinkOptions,
    PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link';
import { Alert, Button, CircularProgress, Stack, Typography } from '@mui/material';
import LoginScreen from './LoginScreen';

export interface LinkTokenResponse {
    expiration: string,
    link_token: string,
    request_id: string
}

interface PlaidSuccessResult{
    public_token:string
    metadata:PlaidLinkOnSuccessMetadata
}
const PlaidLinker = (props:{plaidLink:LinkTokenResponse})=>{
    const {plaidLink} = props
    const [result, setResult] = useState<PlaidSuccessResult>()

    const config: PlaidLinkOptions = {
        onSuccess: (public_token, metadata) => {
            setResult({public_token, metadata})
         },
        onExit: (err, metadata) => { },
        onEvent: (eventName, metadata) => { },
        token: plaidLink.link_token,
    };

    const { open, exit, ready } = usePlaidLink(config);

    useEffect(()=>{
        if(result){
            postJson(`/plaid/links`, JSON.stringify(result), (status, body) => {
                if (status === 200) {
                    console.log("Success", JSON.parse(body))
                }else{
                    JSON.parse(body)
                }
            })
        }
    }, [result])

    return (<>
        {result ? (<>
            <Alert color="info">
                <Typography>You linked {result.metadata.institution?.name}</Typography>
                <ul>
                {result.metadata.accounts.map(account=>{
                    return <>
                        <li>{account.type}/{account.subtype} {account.name} ({account.mask})</li>
                    </>
                })}
                </ul>
            </Alert>
        </>): ready? <div><Button variant='contained' onClick={()=>open()}>Link with Plaid</Button></div> : <CircularProgress/>}
    </>)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

    const [linkToken, setLinkToken] = useState<LinkTokenResponse>()
    const [error, setError] = useState<string>()

    useEffect(() => {
        setError(undefined)
        postJson(`/api/plaid/linkTokens`, "", (status, body) => {
            if (status === 200) {
                setLinkToken(JSON.parse(body))
            }else{
                setError(`Server said ${status}: ${body}`)
            }
        })
    }, [])
    return (<>
    <LoginScreen
        message="Sign-in using your @caruso.com address"
        loggedInContent={(user) =>(
            <Stack spacing={2} style={{margin:"50px"}}>
                <Typography variant="h3">Plaid Linking Demo</Typography>
                {error && <Alert color="error">{error}</Alert>}
                {!linkToken && <CircularProgress/>}
                {linkToken && <PlaidLinker plaidLink={linkToken}/>}
            </Stack>
        )}/>
        
    </>)
}
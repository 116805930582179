import React, { ReactNode } from "react"
import { get } from "./http"
import { StableDataSetDto, StableDataSetIssue } from "./api"
import { Alert, Card, CardContent, CircularProgress, Stack, Typography } from "@mui/material"
import SimpleMarkdown from "./SimpleMarkdown"
import ExportLink from "./ExportLink"

interface DataSetProblem {
    dataSet:StableDataSetDto
    issue:StableDataSetIssue
}


export const getProblemsForDataset = (dataSet:StableDataSetDto, now:Date):DataSetProblem[] => {

    if(dataSet.issues) 
        return  dataSet.issues.map(issue=>{return {dataSet, issue:issue}})
    else
        return []
}

export const DatasetProblemAlert = (props:{problem:DataSetProblem}) => {
    const {problem} = props
    return  <Alert color="error">{problem.issue.description}</Alert>
}

export const DataSetListing = () => {

    const [dataSets, setDataSets] = React.useState<StableDataSetDto[]>()

    const now = new Date()

    React.useEffect(()=>{
        get("/api/data-lake/stable-data-sets", (status, text) => {
            if (status === 200) {
                setDataSets(JSON.parse(text))
            }
        })
    }, [])


    const problemDataSets = dataSets?.filter(dataSet=>getProblemsForDataset(dataSet, now).length>0)

    return (<>
        <Stack spacing={2}>
            {(problemDataSets && problemDataSets.length > 0) && <Alert color="error">{problemDataSets.length} datasets have problems.  See below for details.</Alert>}
            <Typography variant="h6">Overview</Typography>
            <Typography variant="body1"><SimpleMarkdown>{`
These data sets are intended to be used by external systems. 

This means that care will be taken to:
- change them in a backwards-compatible way
- insulate users from upstream/internal implementation details
- protect users from current and future architectural changes
- keep the data accurate when the system changes
          `}</SimpleMarkdown></Typography>
        <Typography variant="h6">The Data Sets</Typography>
        <Stack spacing={2}>
            {!dataSets && <CircularProgress/>}
            {dataSets?.map(dataSet => {
                const pathSegments = dataSet.location.split("/")
                const fileName = pathSegments[pathSegments.length-1]
                return (<>
                    <Card>
                        <CardContent>
                            <Stack spacing={1}>
                                <Typography variant="h6">{dataSet.name}</Typography>
                                <SimpleMarkdown>{dataSet.description}</SimpleMarkdown>
                                <Typography variant="caption">Location: {dataSet.location}</Typography>
                                <Typography variant="caption">Last Updated: {dataSet.lastUpdated && new Date(dataSet.lastUpdated).toUTCString()}</Typography>
                                <Stack direction="row">{getProblemsForDataset(dataSet, now).map(p=><DatasetProblemAlert problem={p}/>)}</Stack>
                                <Stack direction="row"><ExportLink label="download" name={fileName} url={`/api/data-lake/stable-data-sets/${dataSet.id}/exports`} /></Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </>)
            })}
        </Stack>
        </Stack>
    </>)
}


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return (<>
        <Stack spacing={2} style={{ margin: "20px" }}>
            <Typography variant="h4" >Caruso Platform: Stable Data Sets</Typography>
            <DataSetListing/>
        </Stack>
    </>)
}
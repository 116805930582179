import { useEffect, useState } from "react"
import { getReferralCodes } from "./api-actions"
import { GenericReferer, MemberReferer, ReferrerCode, ReferrerRef, StoredReferrerCode, TenantReferer } from "./api"
import { Alert, Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Link, MenuItem, Stack, TextField, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import LabeledSelect from "./LabeledSelect";
import { postJson } from "./http";


const AddReferrerDialog = (props:{onClose:()=>void, onComplete:()=>void})=>{
    const {onClose, onComplete} = props
    const [isWorking, setWorking] = useState(false)
    const [code, setCode] = useState("")
    const [memberId, setMemberId] = useState("")
    const [tenantId, setTenantId] = useState("")
    const [discriminator, setDescriminator] = useState("")
    const [type, setType] = useState("member")
    const [error, setError] = useState<string>()
    
    const doSave = ()=>{
        var referrer:ReferrerRef | undefined = undefined
        if(type === "member"){
            const m:MemberReferer = {
                type:"member",
                memberId: memberId
            }
            referrer = m
        }else if(type === "tenant"){

            const m:TenantReferer = {
                type:"tenant",
                tenantId: tenantId
            }
            referrer = m
        }else if(type === "generic"){

            const m:GenericReferer = {
                type:"generic",
                discriminator: discriminator
            }
            referrer = m
        }
        if(referrer){
            setWorking(true)
            const r:ReferrerCode = {
                code:code,
                referrer:referrer
            }
            postJson(
                "/api/referrer-codes", 
                JSON.stringify(r),
                (status, body, xhr)=>{
                    if(status === 200){
                        onComplete()
                    }else{
                        setError(`Error ${status}: ${body}`)
                    }
                    setWorking(false)
                })
        }else{
            alert("No referrer specified")
        }
    }

    return <Dialog open={true} onClose={onClose}
        fullWidth
        maxWidth="sm">
        <DialogTitle>Add Referrer Tag</DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
            <Stack spacing={2}>
                <TextField
                    required
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    label="Code" />

                <LabeledSelect labelId = "refType" label="Type" value={type} onChange={(e)=>setType(e.target.value)}>
                    <MenuItem value="member">Member</MenuItem>
                    <MenuItem value="tenant">Tenant</MenuItem>
                    <MenuItem value="generic">Generic</MenuItem>
                </LabeledSelect>
                {type === "member" && <>
                    <TextField
                        required
                        value={memberId}
                        onChange={e => setMemberId(e.target.value)}
                        label="MemberId" />
                    </>}

                {type === "tenant" && <>
                    <TextField
                        required
                        value={tenantId}
                        onChange={e => setTenantId(e.target.value)}
                        label="TenantID" />
                    </>}

                {type === "generic" && <>
                    <TextField
                        required
                        value={discriminator}
                        onChange={e => setDescriminator(e.target.value)}
                        label="Discriminator" />
                    </>}

                {error && <Alert color="error">{error}</Alert>}
            </Stack>
        </DialogContent>
        <DialogActions>
            <Box style={{textAlign:"right", minHeight:"100px", width:"100%"}}>

                {!isWorking && <>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="contained" onClick={doSave}>Save</Button>
                </>}
                {isWorking && <Box style={{ width:"50px", height:"50px"}}><CircularProgress style={{width:"50px", height:"50px"}}/></Box>}
            </Box>
        </DialogActions>

    </Dialog>
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ()=>{

    const [tags, setTags] = useState<StoredReferrerCode[]>()
    const [error, setError] = useState<string>()
    const [showDialog, setShowDialog] = useState(false)

    const refresh = () => {
        getReferralCodes().then(setTags).catch(setError)
    }

    useEffect(refresh, [])


    const makeDownloadHandler = (name:string, url:string):React.MouseEventHandler<HTMLAnchorElement> => {
        return (e)=>{
            e.preventDefault()
            const link = document.createElement("a");
            link.download = name;
            link.href = url;
            link.target = "_blank"
            link.click();
        }
    };

    return <>
        {error && <Alert color="error">{error}</Alert>}
        <Stack spacing={3}>
        <Box>{tags?.length == 0 && <Typography>There are no referrer tags</Typography>}</Box>
        <Fab color="primary" style={{position:"fixed", bottom:"30px", right:"30px"}} onClick={()=>setShowDialog(true)}><AddIcon/></Fab>
        {showDialog && <AddReferrerDialog 
            onClose={()=>setShowDialog(false)}
            onComplete={()=>{
                setShowDialog(false)
                refresh()
            }}
            />}
        {tags?.map(storedTag=>{
            const tagId = storedTag.id
            const tag = storedTag.code
            const url = `https://crso.io/j/${tag.code}`
            
            const memberRef = tag.referrer.type === "member" ? tag.referrer as MemberReferer : undefined
            const tenantRef = tag.referrer.type === "tenant" ? tag.referrer as TenantReferer : undefined
            const genericRef = tag.referrer.type === "generic" ? tag.referrer as GenericReferer : undefined
            
            const qrUrlBase = `/api/referrer-codes/${storedTag.id}/qr`
            return <Card>
                <CardContent>
                    <Stack spacing={3}>
                    <Typography>Code: {tag.code}</Typography>
                    <Typography>Referer: {tag.referrer.type} ({memberRef && memberRef.memberId}{tenantRef && tenantRef.tenantId}{genericRef && genericRef.discriminator})</Typography>
                    
                    <a href={url} target="_blank" rel="noreferrer">{url}</a>
                    <img style={{height:"100px", width:"100px"}} src={qrUrlBase} alt="QR Code"/>

                    <Stack direction={'row'} spacing={2}>
                        <Typography>Downloads:</Typography>
                        <Link style={{ cursor:"pointer"}} onClick={makeDownloadHandler(`referrer-${tagId}-qr-code-standard.svg`, `${qrUrlBase}?style=standard`)}>Standard QR</Link>
                        <Link style={{ cursor:"pointer"}} onClick={makeDownloadHandler(`referrer-${tagId}-qr-code-rounded.svg`, `${qrUrlBase}?style=rounded`)}>Rounded QR</Link>
                    </Stack>
                    </Stack>
                </CardContent>
            </Card>
        })}
        </Stack>
    </>
}
import { ReactNode, useEffect, useState } from "react"
import { createTag, updateTag } from "./api-actions"
import { CarusoMap, CoinsTagConfig, DummyTestTagConfig, GenericUrlTagConfig, InPersonFulfillmentTagConfig, MustHaveLinkedCard, OfferCondition, OfferOverview, OfferTagConfig, PartialTagInfo, SignupTagConfig, TagConfig, TagType, } from "./api"
import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Link, MenuItem, Stack, Tab, Tabs, TextField, } from "@mui/material"

import LabeledSelect from "./LabeledSelect";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import UploadFileButton from "./UploadFileButton";
import { ReferrerSelect } from "./ReferrerSelect";

const undefinedIfEmtpy = (s: string): string | undefined => s.trim().length === 0 ? undefined : s


const PartialTagInfoEditor = (props: { value?: PartialTagInfo, onChange: (v: PartialTagInfo) => void }) => {
    const { value, onChange } = props
    const [name, setName] = useState(value?.name || "")
    const [descriptionMarkdown, setDescriptionMarkdown] = useState(value?.styledDescription?.markdown || "")
    const [imageUrl, setImageUrl] = useState(value?.imageUrl || "")

    useEffect(() => {
        const v: PartialTagInfo = {
            name: undefinedIfEmtpy(name),
            styledDescription: {
                markdown: undefinedIfEmtpy(descriptionMarkdown)
            },
            imageUrl: undefinedIfEmtpy(imageUrl),
        }

        if (JSON.stringify(v) !== JSON.stringify(value)) {
            console.log("Partial info: ", v)
            onChange(v)
        }

    }, [name, descriptionMarkdown, imageUrl])

    return (<>
        <TextField
            label="Custom Title"
            value={name}
            onChange={e => setName(e.target.value)}
        />
        <TextField
            label="Custom Description (Markdown)"
            multiline
            value={descriptionMarkdown}
            onChange={e => setDescriptionMarkdown(e.target.value)}
        />
        <Stack direction="row" spacing={2}>

        <TextField
            label="Custom Image URL"
            value={imageUrl}
            fullWidth
            onChange={e => setImageUrl(e.target.value)}
        />
        <UploadFileButton onSuccess={assetUrl => setImageUrl(assetUrl)}>Upload</UploadFileButton>
        </Stack>
    </>)
}


//TagType = "offer"|"dummytag"|"in-person-fulfillment"|"coins"
const dummyUUID = "72c6f232-009d-40c1-9235-4bacfd993b6b"
// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { existing: TagConfig | undefined, map: CarusoMap, offers: OfferOverview[], onClose: () => void, onComplete: (tagId: string) => void }) => {
    const { existing, map, offers, onClose, onComplete } = props
    const [isWorking, setWorking] = useState(false)
    const [type, setType] = useState<TagType>(existing?.type || "coins")
    const [error, setError] = useState<string>()

    const [currentTab, setCurrentTab] = useState<number>(0)

    const existingCoinsTag = (existing && existing.type === "coins") ? existing as CoinsTagConfig : undefined
    const existingInPersonTag = (existing && existing.type === "in-person-fulfillment") ? existing as InPersonFulfillmentTagConfig : undefined
    const existingOfferTag = (existing && existing.type === "offer") ? existing as OfferTagConfig : undefined
    const existingDummyTag = (existing && existing.type === "dummytag") ? existing as DummyTestTagConfig : undefined
    const existingSignupTag = (existing && existing.type === "signup") ? existing as SignupTagConfig : undefined
    const existingGenericUrlTag = (existing && existing.type === "generic-url") ? existing as GenericUrlTagConfig : undefined

    const existingInfo = existingCoinsTag?.info || existingInPersonTag?.info || existingOfferTag?.info || existingSignupTag?.info || existingDummyTag?.info
    const existingValidUntil = existingCoinsTag?.validUntil || existingInPersonTag?.validUntil || existingOfferTag?.validUntil
    const existingSingleUse = existingCoinsTag?.singleUse || existingInPersonTag?.singleUse || existingOfferTag?.singleUse
    const existingConditions = existingCoinsTag?.conditions || existingInPersonTag?.conditions || existingOfferTag?.conditions
    const existingLinkedCardCondition = existingConditions?.find(c => c.type === "linked-card") // FIXME: seems to be always true?
    const existingOfferId = existingInPersonTag?.offerId || existingOfferTag?.offerId

    const [internalName, setInternalName] = useState(existing?.internalName ?? "")
    const [numCoins, setNumCoins] = useState(existingCoinsTag?.numCoins || 0)
    const [outsiderDescription, setoOutsiderDescription] = useState(existing?.outsiderDescription || "")
    const [partialInfo, setPartialInfo] = useState(existingInfo)
    const [validUntil, setValidUntil] = useState(existingValidUntil ? dayjs(existingValidUntil) : undefined)
    const [singleUse, setSingleUse] = useState(existingSingleUse ? existingSingleUse : false)
    const [mustHaveLinkedCard, setMustHaveLinkedCard] = useState(existingLinkedCardCondition ? true : false)
    const [linkedCardText, setLinkedCardText] = useState(existingLinkedCardCondition?.summary || "You must have a linked card")
    const [propertyId, setPropertyId] = useState<number|undefined>(existing?.propertyId)
    const [offerId, setOffer] = useState(existingOfferId)
    const [genericUrl, setGenericUrl] = useState(existingGenericUrlTag?.url ?? "http://caruso.com")
    const [useMemberTracking, setUseMemberTracking] = useState(existingGenericUrlTag ? (existingGenericUrlTag.useMemberTracking ?? false) : true)

    const [referrer, setReferrer] = useState(existing?.referrer)

    const doSave = () => {
        var tag: TagConfig | undefined = undefined
        const vu = validUntil?.unix()
        const savableTagId = existing?.tagId || dummyUUID

        const m: MustHaveLinkedCard | undefined = mustHaveLinkedCard ? {
            type: "linked-card",
            summary: linkedCardText,
        } : undefined

        const conditions: OfferCondition[] | undefined = m && [m]

        // FIXME: Should probably be a switch rather than if/else chain?
        if (type === "coins") {
            const coinsTag: CoinsTagConfig = {
                tagId: savableTagId,
                internalName: internalName,
                type: "coins",
                numCoins: numCoins,
                conditions,
                validUntil: vu && (vu * 1000),
                singleUse: singleUse,
                propertyId: propertyId || 0,
                referrer: referrer,
                outsiderDescription: outsiderDescription,
                info: partialInfo
            }
            tag = coinsTag
        } else if (type === "in-person-fulfillment") {
            const coinsTag: InPersonFulfillmentTagConfig = {
                tagId: savableTagId,
                internalName: internalName,
                type: "in-person-fulfillment",
                conditions,
                validUntil: vu && (vu * 1000),
                singleUse: singleUse,
                propertyId: propertyId || 0,
                referrer: referrer,
                outsiderDescription: outsiderDescription,
                info: partialInfo,
                offerId: offerId,
            }
            tag = coinsTag
        } else if (type === "offer") {
            if (offerId) {
                const coinsTag: OfferTagConfig = {
                    tagId: savableTagId,
                    internalName: internalName,
                    type: "offer",
                    conditions,
                    validUntil: vu && (vu * 1000),
                    singleUse: singleUse,
                    propertyId: propertyId || 0,
                    referrer: referrer,
                    outsiderDescription: outsiderDescription,
                    info: partialInfo,
                    offerId: offerId,
                }
                tag = coinsTag
            }
        } else if (type === "dummytag") {

            const coinsTag: DummyTestTagConfig = {
                tagId: savableTagId,
                internalName: internalName,
                type: "dummytag",
                propertyId: propertyId || 0,
                referrer: referrer,
                outsiderDescription: outsiderDescription,
                info: partialInfo,
            }
            tag = coinsTag
        } else if (type === "signup") {

            const coinsTag: SignupTagConfig = {
                tagId: savableTagId,
                internalName: internalName,
                type: "signup",
                outsiderDescription: outsiderDescription,
                propertyId: propertyId || 0,
                referrer: referrer,
                info: partialInfo,
            }
            tag = coinsTag
        } else if (type === "generic-url") { //FIXME: seemingly always true?
            const coinsTag: GenericUrlTagConfig = {
                tagId: savableTagId,
                internalName: internalName,
                type: "generic-url",
                propertyId: propertyId || 0,
                referrer: referrer,
                outsiderDescription: outsiderDescription,
                url: genericUrl || "https://carusosignature.com",
                useMemberTracking: useMemberTracking,
            }
            tag = coinsTag
        } 

        if (tag) {
            setWorking(true)

            const operation = existing ? updateTag(tag).then(t => existing.tagId) : createTag(tag).then(t => t.tag.tagId)

            operation
                .then(onComplete)
                .catch(setError)
                .finally(() => setWorking(false))
        }
    }
    const typeSupportsConditions = (type !== "dummytag" && type !== "signup" && type !== "generic-url")
    const allTypes: TagType[] = ["offer", "dummytag", "in-person-fulfillment", "coins", "signup", "generic-url"]

    const tabs: Record<string, () => ReactNode> = {
        "Configuration": () => <Stack spacing={3}>

            <TextField
                required
                type="text"
                value={internalName}
                onChange={e => setInternalName(e.target.value)}
                label="Internal Name (not visible to users)" />
            
            <LabeledSelect labelId="refType" label="Type" required value={type} onChange={(e) => setType(e.target.value as TagType)}>
                {allTypes.map(type => {
                    return <MenuItem value={type}>{type}</MenuItem>
                })}
            </LabeledSelect>
            {type === "coins" && <>
                <TextField
                    required
                    minRows={10}
                    type="number"
                    value={numCoins}
                    onChange={e => setNumCoins(parseInt(e.target.value, 10))}
                    label="Number of Coins" />
            </>}

            {type === "generic-url" && <>
                <TextField
                    required
                    minRows={10}
                    type="url"
                    value={genericUrl}
                    onChange={e => setGenericUrl(e.target.value)}
                    label="Destination URL" />

                <FormControlLabel control={<Checkbox checked={useMemberTracking} onChange={(e, checked) => setUseMemberTracking(checked)} />} label="Use member-tracking redirects" />

            </>}


            {(type === "in-person-fulfillment" || type === "offer") && <>
                <LabeledSelect labelId="refType" label="Offer" required={type === "offer"} value={offerId} onChange={(e) => setOffer(e.target.value as number)}>
                    {offers.map(offer => {
                        return <MenuItem value={offer.id}>{offer.label} ({offer.id}/{offer.internal_name})</MenuItem>
                    })}
                </LabeledSelect>
            </>}

            <LabeledSelect labelId="refType2" label="Property" value={propertyId} onChange={(e) => setPropertyId(e.target.value as number)}>
                <MenuItem value={undefined}>
                    <em>None</em>
                </MenuItem>
                {map?.properties.map(property => {
                    return <MenuItem value={property.id}>{property.name}</MenuItem>
                })}
            </LabeledSelect>
            <Box>Referrer: <ReferrerSelect value={referrer} onChange={setReferrer}/></Box>
            <DateTimePicker label="Valid Until" value={validUntil} onChange={v => setValidUntil(v || undefined)} />
        </Stack>,

    }

    if (typeSupportsConditions) {
        tabs['Conditions'] = () => (<Stack spacing={3}>

            <FormGroup>
                <FormControlLabel control={<Checkbox checked={singleUse} onChange={(e, checked) => setSingleUse(checked)} />} label="Single Use" />
                <FormControlLabel control={<Checkbox checked={mustHaveLinkedCard} onChange={(e, checked) => setMustHaveLinkedCard(checked)} />} label="Must have linked card" />
            </FormGroup>

            {mustHaveLinkedCard && <TextField
                label="Linked Card Requirement Text"
                multiline
                value={linkedCardText}
                onChange={e => setLinkedCardText(e.target.value)}
            />}

        </Stack>)
    }
    const markdownDocumentationNote = <Alert color="info">See <Link href="https://www.markdownguide.org/cheat-sheet/" target="_blank">this site</Link> for advanced information on markdown format</Alert>

    if(type !== "generic-url"){

        tabs['Experience without App Installed'] = () => (<Stack spacing={3}>
            <TextField
                label="Description (Markdown)"
                multiline
                required
                value={outsiderDescription}
                onChange={e => setoOutsiderDescription(e.target.value)}
            />
            <Alert color="info">Use the '{"<download-links/>"}' tag to create standard mobile download links</Alert>
            {markdownDocumentationNote}
        </Stack>)
    
        tabs['App Popup'] = () => (<Stack spacing={3}>
    
    
            
            <PartialTagInfoEditor
                value={partialInfo}
                onChange={setPartialInfo}
            />
            {markdownDocumentationNote}
        </Stack>)
    }

    function a11yProps(index: string) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    const tabFn = tabs[Object.keys(tabs)[currentTab]]
    console.log("Tab is ", currentTab, tabFn)

    return <Dialog open={true} onClose={onClose}
        fullWidth
        
        maxWidth="lg">
        <DialogTitle>Pickup Tag</DialogTitle>
        <DialogContent style={{ padding: "20px", minHeight:"600px" }}>


            <Stack spacing={3}>
                <Tabs value={currentTab} onChange={(e, n) => setCurrentTab(n)} aria-label="basic tabs example">
                    {Object.keys(tabs).map((name) => {
                        return <Tab key={name} label={name} {...a11yProps(name)} />
                    })}
                </Tabs>
                {tabFn()}
                {error && <Alert color="error">{error}</Alert>}
            </Stack>
        </DialogContent>
        <DialogActions>
            <Box style={{ textAlign: "right", minHeight: "100px", width: "100%" }}>

                {!isWorking && <>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="contained" onClick={doSave}>Save</Button>
                </>}
                {isWorking && <Box style={{ width: "50px", height: "50px" }}><CircularProgress style={{ width: "50px", height: "50px" }} /></Box>}
            </Box>
        </DialogActions>

    </Dialog>
}

import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType } from '@zxing/library'
import { useEffect, useRef, useState } from 'react';


const createReader = ():BrowserMultiFormatReader => {
    const r = new BrowserMultiFormatReader()

    const hints = new Map();
    const formats = [
        BarcodeFormat.QR_CODE, 
        BarcodeFormat.AZTEC, 
        BarcodeFormat.CODABAR, 
        BarcodeFormat.CODE_128,
        BarcodeFormat.CODE_93,
        BarcodeFormat.CODE_39,
        BarcodeFormat.DATA_MATRIX,
        BarcodeFormat.EAN_13,
        BarcodeFormat.EAN_8,
        BarcodeFormat.ITF,
        BarcodeFormat.UPC_A,
        BarcodeFormat.UPC_E,
        BarcodeFormat.UPC_EAN_EXTENSION,
        BarcodeFormat.RSS_14,
        BarcodeFormat.RSS_EXPANDED];

    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

    r.hints = hints

    return r
}

const BarcodeScanner = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const reader = useRef(createReader());
    const [results, setResults] = useState<string[]>([])

    useEffect(() => {
        let innnerResults = results
        if (!videoRef.current) return;
        reader.current.decodeFromConstraints(
            {
                audio: false,
                video: {
                    facingMode: 'environment',
                },
            },
            videoRef.current,
            (newResult, newError) => {
                const r = newResult?.toString() ?? newError?.message

                const lastResult = innnerResults[innnerResults.length-1]
                if (r && r !== '' && r !== lastResult) {
                    console.log("newResult",  r, "vs", lastResult, typeof  r)
                    innnerResults = innnerResults.concat([r])
                    setResults(innnerResults)
                }
            }
        );
        return () => {
            reader.current.reset();
        }
    }, [videoRef]);

    return (<>
        <video ref={videoRef} />
        {results.map(r => <div>{r?.toString()}</div>)}
    </>)
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return (<>
        <BarcodeScanner />
    </>)
}
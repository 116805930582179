
export const del = (url: string, onResponse: (status: number, body: string, r: XMLHttpRequest) => void) => {
    const xhr = new XMLHttpRequest();
    xhr.open("DELETE", url, true);

    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            onResponse(xhr.status, xhr.responseText, xhr)
        }
    }
    xhr.send();
}

export const sendJson = (method:"POST"|"PUT"|"PATCH", url: string, body: string, onResponse: (status: number, body: string, r: XMLHttpRequest) => void) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);

    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            onResponse(xhr.status, xhr.responseText, xhr)
        }
    }
    xhr.send(body);
}

export const postJson = (url: string, body: string, onResponse: (status: number, body: string, r: XMLHttpRequest) => void) => {
    sendJson("POST", url, body, onResponse)
}
export const patchJson = (url: string, body: string, onResponse: (status: number, body: string, r: XMLHttpRequest) => void) => {
    sendJson("PATCH", url, body, onResponse)
}
export const get = (url: string, onResponse: (status: number, body: string, r: XMLHttpRequest) => void) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);

    xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            onResponse(xhr.status,xhr.responseText, xhr)
        }
    }
    xhr.send();
}
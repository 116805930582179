
export const numLeadingSpaces = (str:string) => {
    var n = 0
    while(str[n] === " "){
        n = n + 1
    }
    return n
}

export const min = (items:number[]):number|undefined => {
    var min:number|undefined = undefined

    items.forEach(n => {
        if(min === undefined || min > n){
            min = n
        }
    })

    return min
}

export const minusIndent = (str:string) => {
    const lines = str.split("\n")
    const prefix = min(lines.filter(line=>line.length>0).map(numLeadingSpaces)) ?? 0
    return lines.map(line => line.substring(prefix)).join("\n")
}

export const mapKeys = (
    obj: Object,
    fn: (v: Object, k: string, o: Object) => string
  ) =>
    Object.keys(obj).reduce((acc, k: string) => {
      (acc as any)[fn((obj as any)[k], k, obj)] = (obj as any)[k];
      return acc;
    }, {} as Object);



export const flatten = (prefix: string, o: any): Record<string, string | boolean | number | undefined> => {
    const results: Record<string, string | boolean | number | undefined> = {}

    const p = prefix.length > 0 ? `${prefix}.` : ""

    const final = Object.keys(o).reduce((accum, key) => {
        const k = `${p}${key}`
        const v = o[key]
        const t = typeof v
        let newstuff: Record<string, string | boolean | number | undefined> = {}
        if (t === "string") {
            newstuff[k] = v as string
        } else if (t === "number") {
            newstuff[k] = v as number
        } else if (t === "boolean") {
            newstuff[k] = v as boolean
        } else if (t === "object") {
            newstuff = flatten(k, v)
        }

        return {
            ...accum,
            ...newstuff
        }
    }, results)

    return final
}


export function formatAmount(qty:number, currency:string) {
    if (currency === "USD") {
        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return USDollar.format(qty)
    } else {
        return qty + " (" + currency + ")";
    }
}

export let formattedColorAmount = (quantity:number, currency:string) => {
    var classes = ((quantity < 0) ? "negative-amount" : ((quantity > 0) ? "positive-amount" : "zero-amount"));
    return <span className={classes}>{formatAmount(quantity, currency)}</span>
}

export const formatPlural = (val:number, singularDescription:string, pluralDescription:string):string => (val === 1) ? singularDescription : pluralDescription

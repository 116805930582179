import React, { useEffect } from "react"
import { del, get, postJson } from "./http"
import { Alert, Box, Button, Checkbox, CircularProgress, Fab, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { getSmsTestingConfig, saveSmsTestingConfig } from "./api-actions"
import { SMSTestAccountConfig, SMSTestConfig } from "./api"
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

export default (props: {}) => {

    const [isBusy, setBusy] = React.useState(false)
    const [error, setError] = React.useState<string>()
    const [results, setResults] = React.useState<SMSTestConfig>()

    const fetchUsers = () => {
        setBusy(true)
        setError(undefined)
        getSmsTestingConfig()
            .then(setResults)
            .then(()=>setBusy(false))
            .catch(setError)
    }

    const doAdd = ()=>{
        if(results){
            setResults({
                ... results,
                testAccounts:results.testAccounts.concat([
                    {
                        label:"unnamed",
                        localNumberPrefix:"444", 
                        redirectDestination:{
                            countryCode:"1",
                            number:"8058675309",
                        },
                        staticCode:undefined,
                    }
                ])
            })
        }
    }

    const doSave = ()=>{
        if(results){
            setBusy(true)
            saveSmsTestingConfig(results)
                .catch(setError)
                .finally(()=>setBusy(false))
        }
    }

    useEffect(fetchUsers, [])

        return (<>
        
        <Stack spacing={3} style={{margin:"20px"}}>
            <Typography variant="h5">SMS Test Accounts <Fab color="primary" style={{float:"right"}} onClick={doAdd}><AddIcon/></Fab></Typography>
            {error && <Alert color="error">{error}</Alert>}
            {isBusy && <CircularProgress/>}
            {results && 
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Label</TableCell>
                        <TableCell align="left">Country and Area Code</TableCell>
                        <TableCell align="left">Number Prefix</TableCell>
                        <TableCell align="left">Redirects To</TableCell>
                        <TableCell align="left">Static Code</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results.testAccounts.map((result, idx) => {
                        const handleDelete = ()=>{
                            console.log("Delete", idx, "from", results.testAccounts)
                            const copy = [... results.testAccounts]
                            copy.splice(idx, 1)
                            setResults({
                                ... results,
                                testAccounts:copy
                            })
                        }
                        const handleUpdate = (update:SMSTestAccountConfig)=>{
                            const testAccounts:SMSTestAccountConfig[] = results.testAccounts.map((n, i)=>{
                                if(i == idx){
                                    return update
                                }else{
                                    return n
                                }
                            })
                            setResults({
                                ... results,
                                testAccounts:testAccounts
                            })
                        }

                        const handleDestinationUpdate = (s:string)=>{
                            handleUpdate({
                                ... result,
                                redirectDestination:{
                                    ... result.redirectDestination,
                                    number:s,
                                }
                            })
                        }
                        const handleCodeUpdate = (s:string)=>{
                            handleUpdate({
                                ... result,
                                staticCode:(s.trim().length==0) ? undefined : s.trim()
                            })
                        }

                        return (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left">{<TextField label="Label"  value={result.label ?? ""} onChange={e=>handleUpdate({... result, label:e.target.value})}></TextField>}</TableCell>

                                <TableCell align="left"> +1 ({results.areaCode})</TableCell>
                                <TableCell align="left">{<TextField label="Local Number Prefix" type="number" value={result.localNumberPrefix} onChange={e=>handleUpdate({... result, localNumberPrefix:e.target.value})}></TextField>}</TableCell>
                                <TableCell align="left">{<TextField label="North-American 9-Digit #" type="number" value={result.redirectDestination.number} onChange={e=>handleDestinationUpdate(e.target.value)}></TextField>}</TableCell>
                                <TableCell align="left">{<TextField label="Static Code" type="number" value={result.staticCode || ""} onChange={e=>handleCodeUpdate(e.target.value)}></TextField>}</TableCell>
                                <TableCell align="right"><IconButton onClick={handleDelete}><DeleteIcon/></IconButton></TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            }

            <Box style={{textAlign:"right"}}>
                <Button variant="contained" onClick={doSave}>Save</Button>
            </Box>
        </Stack>
        </>)
}
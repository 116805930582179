import { postJson, get } from "./http";

interface ExportIdWrapper {
    exportId:string
}
export const doExport = (url:string):Promise<string> => {

    return new Promise((resolveSuccess, resolveFailed)=>{

        const check = (w:ExportIdWrapper)=>{
            const {exportId} = w
            get(`/api/exports/${exportId}`,  (status, body)=>{
                if(status === 200){
                    console.log("Download is ready")
                    resolveSuccess(`/api/exports/${exportId}/data`)
                }else if(status === 500){
                    resolveFailed(body)
                }else{

                    setTimeout(()=>check(w), 1000)
                }
            })
        }

        postJson(
            url,
            "",
            (status, body)=>{
                if(status === 200){
                    check(JSON.parse(body))
                }else{
                    resolveFailed({status:status, body:body})
                }
            }
        )
    })
}